import styled from "styled-components";
import { Column } from "../layout/Flex";

// Overlay for non generating sites to be displayed on top of the chart
export const NonGeneratingSiteOverlay = styled(Column)`
  position: absolute;
  top: 0;
  left: 15%;
  width: 70%;
  height: 100%;
  background-color: transparent;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
