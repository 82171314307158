import React from "react";
import styled from "styled-components";
import formatPercentage from "../../formatting/formatPercentage";
import circleQuestionMarkTick from "../../images/icons/circleQuestionMarkTick.svg";
import Tooltip from "../Tooltip";

interface EnergySupplyProps {
  isGeneratingSite: boolean;
  peakInRange: number;
  daylightInRange?: number;
}

const EnergySupplySidebar: React.FC<EnergySupplyProps> = ({
  isGeneratingSite,
  peakInRange,
  daylightInRange,
}) => {
  return (
    <SideBar>
      <MetricTooltip>
        <Tooltip
          text={
            "Of this site’s total annual demand, some peak and off-peak portions are met by InRange, " +
            "and some peak and off-peak portion are met by other suppliers, based on the amount " +
            "and timing of the on-site generation and the tenant’s demand patterns."
          }
        >
          <img src={circleQuestionMarkTick} alt="info" />
        </Tooltip>
      </MetricTooltip>
      <span>
        <SideBarHeader>Energy supply by source and time</SideBarHeader>
        <PeakHoursInfo />
        <EnergyDemandMetric
          label={
            isGeneratingSite
              ? "Of peak demand supplied by on-site generation & the InRange network"
              : "Of peak demand supplied by the InRange network"
          }
          value={peakInRange}
        />
        <EnergyDemandMetric
          label={"Of peak demand supplied by other suppliers"}
          value={1 - peakInRange}
        />
        {daylightInRange !== undefined && (
          <>
            <EnergyDemandMetric
              label={
                isGeneratingSite
                  ? "Of daylight demand supplied by on-site generation & the InRange network *"
                  : "Of daylight demand supplied by the InRange network *"
              }
              value={daylightInRange}
            />
            <EnergyDemandMetric
              label={"of daylight demand supplied by other suppliers *"}
              value={1 - daylightInRange}
            />
            <EnergyDemandMetricLabel>
              * daylight hours are estimated
            </EnergyDemandMetricLabel>
          </>
        )}
      </span>
    </SideBar>
  );
};

export default EnergySupplySidebar;

interface EnergyDemandMetricProps {
  value: number;
  label: string;
}

const EnergyDemandMetric: React.FC<EnergyDemandMetricProps> = ({
  value,
  label,
}) => {
  return (
    <EnergyDemandMetricWrapper>
      <EnergyDemandMetricValue>
        {formatPercentage(value, 0)}
      </EnergyDemandMetricValue>
      <EnergyDemandMetricLabel>{label}</EnergyDemandMetricLabel>
    </EnergyDemandMetricWrapper>
  );
};

const PeakHoursInfo: React.FC = () => {
  return (
    <PeakHoursInfoWrapper>
      <PeakHoursInfoPeriod name="Peak hours" start="8:00 am" end="10:00pm" />
      <PeakHoursInfoPeriod name="Off-peak hours" start="10:00pm" end="8:00am" />
    </PeakHoursInfoWrapper>
  );
};

interface PeakHoursInfoPeriodProps {
  name: string;
  start: string;
  end: string;
}

const PeakHoursInfoPeriod: React.FC<PeakHoursInfoPeriodProps> = ({
  name,
  start,
  end,
}) => {
  return (
    <PeakHoursInfoPeriodWrapper>
      <PeakHoursInfoPeriodName>{name}</PeakHoursInfoPeriodName>
      <PeakHoursInfoPeriodStartEnd>
        {start} - {end}
      </PeakHoursInfoPeriodStartEnd>
    </PeakHoursInfoPeriodWrapper>
  );
};

const MetricTooltip = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const PeakHoursInfoWrapper = styled.div`
  display: flex;
  background-color: white;
  padding: 8px;
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  margin: 32px 0;
`;

const PeakHoursInfoPeriodWrapper = styled.div`
  margin: 0 20px;
  padding: 16px 0;
`;
const PeakHoursInfoPeriodName = styled.p`
  margin: 0 0 8px;
  padding: 0;
`;
const PeakHoursInfoPeriodStartEnd = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 800;
`;

const SideBarHeader = styled.h1`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: white;
  margin: 0;
  padding: 0;
`;

const SideBar = styled.div`
  padding: 16px;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  margin-left: 16px;
  width: 470px;
  flex-basis: 470px;
  flex-shrink: 0;
  span {
    color: white;
  }
`;

const EnergyDemandMetricWrapper = styled.div`
  margin: 20px 0;
`;
const EnergyDemandMetricValue = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 32px;
  span {
    display: block;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.blueSolid};
  }
`;
const EnergyDemandMetricLabel = styled.div`
  margin: 8px 0;
  padding: 0;
`;
