import { Link } from "react-router-dom";
import styled from "styled-components";

export interface BreadCrumb {
  label: string;
  url?: string;
  onClick?: () => void;
}

const BreadCrumbs = ({ crumbs }: { crumbs: BreadCrumb[] }) => {
  return (
    <BreadCrumbsContainer>
      {crumbs.map((crumb, index) => (
        <Fragment key={crumb.label}>
          <BreadCrumb
            isNotLast={index < crumbs.length - 1 && crumbs.length > 1}
            data-testid={`breadcrumb-${index}`}
          >
            <BreadCrumbText index={index} crumb={crumb} />
          </BreadCrumb>
          {index !== crumbs.length - 1 && <Separator>/</Separator>}
        </Fragment>
      ))}
    </BreadCrumbsContainer>
  );
};

const BreadCrumbText = ({ crumb }: { index: number; crumb: BreadCrumb }) => {
  if (crumb.url) {
    return (
      <Link to={crumb.url} onClick={crumb.onClick}>
        {crumb.label}
      </Link>
    );
  }
  return crumb.label;
};

const BreadCrumbsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Fragment = styled.div`
  display: flex;
  align-items: center;
`;

const BreadCrumb = styled.div<{ isNotLast: boolean }>`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  a {
    color: ${({ theme }) => theme.colors.grey900};
    text-decoration: none;
  }
  a:hover {
    color: black;
  }
  ${({ isNotLast, theme }) =>
    isNotLast && {
      color: theme.colors.grey900,
    }}
`;

const Separator = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #000000;
  margin: 0 8px;
`;

export default BreadCrumbs;
