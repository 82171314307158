import { useSiteList } from "@inrange/building-manager-api-client";
import Loading from "../Loading";
import MapOfSites from "./MapOfSites";

const SiteMap: React.FC = () => {
  const { fetchSites } = useSiteList();

  if (fetchSites.isLoading)
    return <Loading label="Loading site map..." height={"800px"} />;

  return <MapOfSites sites={fetchSites.data?.sites} />;
};

export default SiteMap;
