export const formatNumber2dp = (
  value: number | string,
  useGrouping: boolean = true
): string => {
  return formatNumber(value, 2, 2, useGrouping);
};

export const formatNumber = (
  value: number | string,
  minimumNumFractionDigits: number,
  maximumFractionDigits: number,
  useGrouping: boolean = true
): string => {
  if (typeof value === "number") {
    const numberFormat = new Intl.NumberFormat("en-GB", {
      style: "decimal",
      minimumFractionDigits: minimumNumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
      //@ts-expect-error - TS doesn't know about the roundingMode option
      roundingMode: "trunc",
      useGrouping: useGrouping,
    });
    return numberFormat.format(value);
  }
  const parsedValue = parseFloat(value);
  if (isNaN(parsedValue)) {
    return value;
  }

  const endsWithDecimal = value.endsWith(".");

  const numberFormat = new Intl.NumberFormat("en-GB", {
    style: "decimal",
    maximumFractionDigits: maximumFractionDigits,
    //@ts-expect-error - TS doesn't know about the roundingMode option
    roundingMode: "trunc",
    useGrouping: useGrouping,
  });
  const formattedValue = numberFormat.format(parsedValue);

  const originalDecimals = value.split(".")[1]?.length || 0;
  const formattedDecimals = formattedValue.split(".")[1]?.length || 0;
  if (originalDecimals > maximumFractionDigits) {
    return formattedValue;
  }

  // Pad with zeros if needed
  if (originalDecimals > formattedDecimals) {
    const [whole, fraction = ""] = formattedValue.split(".");
    const paddedFraction = fraction.padEnd(originalDecimals, "0");
    return `${whole}.${paddedFraction}`;
  }

  return endsWithDecimal ? formattedValue + "." : formattedValue;
};

export const epochSecondsToDate = (epochSeconds: number): string => {
  if (!epochSeconds) return "";
  const date = new Date(epochSeconds * 1000);
  return date.toISOString().split("T")[0];
};

export const currencyValue = (value: number, currencyCode: string): string => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const handleCompareNumberWithString = (
  objValue: number | string,
  othValue: number | string
): boolean | undefined => {
  if (typeof objValue === "number" && typeof othValue === "string") {
    return objValue === parseFloat(othValue);
  }
  if (typeof objValue === "string" && typeof othValue === "number") {
    return parseFloat(objValue) === othValue;
  }
  // Return undefined to let lodash handle other comparisons
  return undefined;
};
