import { useSiteSdm } from "@inrange/building-manager-api-client";
import { Icons } from "@inrange/theme-components/icons";
import { AxiosError } from "axios";
import { useState } from "react";

import {
  SdmMatchConfig,
  SdmSiteOffer,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import {
  reversed,
  sortBy,
  sortByAdditional,
} from "@inrange/calculations/utils.ts";
import { Button, Card, Form, Table } from "react-bootstrap";
import { UseMutationResult } from "react-query";
import isSiteOwnerOccupied from "../../../utils/isSiteOwnerOccupied";
import Loading from "../../Loading";
import SdmOfferRow from "./SdmOfferRow";

const buyOfferLargeEnoughForThisSite = (
  sdm: any,
  minMarketplaceMatchSize: number
) => {
  return sdm.buy.config.volume > minMarketplaceMatchSize;
};

const buyOfferLargeEnoughForOtherSite = (sdm: any) => {
  return sdm.buy.config.volume > sdm.dest.minMarketplaceMatchSize;
};

const sellOfferLargeEnoughForThisSite = (
  sdm: any,
  minMarketplaceMatchSize: number
) => {
  return sdm.sell.config.volume > minMarketplaceMatchSize;
};

const sellOfferLargeEnoughForOtherSite = (sdm: any) => {
  return sdm.sell.config.volume > sdm.dest.minMarketplaceMatchSize;
};

const capitalize = (str: string) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const SdmOffers: React.FC<{
  site: Site;
  updateSite: UseMutationResult<any, unknown, any, unknown>;
  setSelectedAddOffer: (offer: SdmMatchConfig | undefined) => void;
}> = ({ site, updateSite, setSelectedAddOffer }) => {
  const [minMarketplaceMatchSize, setMinMarketplaceMatchSize] =
    useState<string>(String(site.minMarketplaceMatchSize));
  const [maxWiredDistanceKm, setMaxWiredDistanceKm] = useState<string>(
    String(site.maxWiredDistanceKm)
  );
  const [filterMatchDno, setFilterMatchDno] = useState<boolean>(false);
  const [filterMatchingOrg, setFilterMatchingOrg] = useState<boolean>(false);
  const [filterCustomerFacing, setFilterCustomerFacing] =
    useState<boolean>(true);
  const [filterBiDirectionalMatches, setFilterBiDirectionalMatches] =
    useState<boolean>(false);
  const [filterSamePark, setFilterSamePark] = useState<boolean>(
    site.exportLimit === 0 && site.siteOwnerships.some((so) => !!so.park)
  );
  const [filterWired, setFilterWired] = useState<boolean>(
    site.exportLimit === 0 ? true : false
  );
  const [sortColumn, setSortColumn] = useState<string>("sell");
  const [sortDirection, setSortDirection] = useState<string>(
    site.exportLimit === 0 ? "asc" : "desc"
  );
  const { fetchSiteSdmList } = useSiteSdm({
    siteId: site.id,
    getAllResults: true,
    isWired: filterWired,
    onlyLinkedOrgs: !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE,
  });
  const isOwnerOccupied = isSiteOwnerOccupied(site.siteOwnerships);

  const saveSite = (siteUpdates: any) => {
    const update = {
      site: {
        ...siteUpdates,
        updateHubSpot: false,
      },
    };
    updateSite.mutate(update);
  };

  if (updateSite.isSuccess) {
    updateSite.reset();
    window.location.reload();
  }

  if (updateSite.isError) {
    const error = updateSite.error as AxiosError;
    const errorMessage = error.response?.data?.["message"] || error.message;
    alert(`Error updating site - ${errorMessage}`);
    updateSite.reset();
  }

  if (
    fetchSiteSdmList.isError ||
    (!fetchSiteSdmList.isLoading &&
      fetchSiteSdmList.data &&
      "results" in fetchSiteSdmList.data &&
      !fetchSiteSdmList.data.results)
  ) {
    return <div>Failed to compute import/export matches.</div>;
  }

  const sdmList =
    fetchSiteSdmList.data && "results" in fetchSiteSdmList.data
      ? fetchSiteSdmList.data?.results
      : [];

  const siteDno = site.dno!.name;

  const filteredSdmMatches = sdmList.filter((sdm: any) => {
    if (filterMatchDno && sdm.dest.dno !== siteDno) {
      return null;
    }
    if (filterMatchingOrg && sdm.dest.from_additional_org) {
      return null;
    }
    if (filterCustomerFacing && sdm.dest.is_scenario) {
      return null;
    }
    if (filterSamePark && !sdm.dest.is_same_park) {
      return null;
    }
    if (filterBiDirectionalMatches) {
      if (
        !(
          buyOfferLargeEnoughForThisSite(
            sdm,
            Number(minMarketplaceMatchSize)
          ) && buyOfferLargeEnoughForOtherSite(sdm)
        ) &&
        !(
          sellOfferLargeEnoughForThisSite(
            sdm,
            Number(minMarketplaceMatchSize)
          ) && sellOfferLargeEnoughForOtherSite(sdm)
        )
      ) {
        return null;
      }
    }
    return sdm;
  });

  const sortFunction = filterWired
    ? sortBy((a: any) => a.dest.km)
    : sortColumn === "buy"
      ? sortBy((a: any) => a.buy.config.volume)
      : sortBy((a: any) => a.sell.financialModels.landlord.license.irr);
  const sortDirectionFunction =
    sortDirection === "asc" ? sortFunction : reversed(sortFunction);
  const sortDirectionSecondarySortByName = sortByAdditional(
    (a: any) => a.dest.name,
    sortDirectionFunction
  );
  const sortedSdmMatches = filteredSdmMatches.sort(
    sortDirectionSecondarySortByName
  );

  const sdmRows = sortedSdmMatches.map(
    (sdmSite: SdmSiteOffer, index: number) => (
      <SdmOfferRow
        site={site}
        sdmSite={sdmSite}
        key={index}
        isOwnerOccupied={isOwnerOccupied}
        isWired={filterWired}
        setSelectedAddOffer={setSelectedAddOffer}
        minMarketplaceMatchSize={Number(minMarketplaceMatchSize)}
      />
    )
  );

  return (
    <Card body>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <h6 style={{ fontWeight: "bold" }}>
          Import/Export matches ({sdmRows.length})
        </h6>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "auto",
          }}
        >
          <Form.Check
            inline
            type="switch"
            label="Same exclusions as customer-facing"
            checked={filterCustomerFacing}
            onChange={() => setFilterCustomerFacing(!filterCustomerFacing)}
          />
          <Form.Check
            inline
            type="switch"
            label="Matching organisation"
            checked={filterMatchingOrg}
            onChange={() => setFilterMatchingOrg(!filterMatchingOrg)}
          />
          <Form.Check
            inline
            type="switch"
            label="Matching park"
            checked={filterSamePark}
            onChange={() => setFilterSamePark(!filterSamePark)}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Form.Check
            inline
            type="switch"
            label="Bi-directional matches"
            checked={filterBiDirectionalMatches}
            onChange={() =>
              setFilterBiDirectionalMatches(!filterBiDirectionalMatches)
            }
          />
          <Form.Check
            inline
            type="switch"
            label="Matching DNO"
            checked={filterMatchDno}
            onChange={() => setFilterMatchDno(!filterMatchDno)}
          />
          <Form.Check
            inline
            type="switch"
            label="Wired"
            checked={filterWired}
            onChange={() => {
              setFilterWired(!filterWired);
              setSortDirection(!filterWired ? "asc" : "desc");
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: "8px",
          }}
        >
          <Form.Group className="pb-2" controlId="matchThreshold">
            <Form.Label>Match threshold (kWh)</Form.Label>
            <Form.Control
              type="text"
              value={minMarketplaceMatchSize}
              onChange={(e) => {
                setMinMarketplaceMatchSize(e.target.value);
              }}
            />
          </Form.Group>
          <Button
            variant="primary"
            onClick={() => {
              saveSite({ minMarketplaceMatchSize: minMarketplaceMatchSize });
            }}
            disabled={
              site.minMarketplaceMatchSize === Number(minMarketplaceMatchSize)
            }
            style={{ padding: "0.375rem", width: "100%" }}
            size="sm"
          >
            Save threshold
          </Button>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Form.Group className="pb-2" controlId="maxWiredDistanceKm">
            <Form.Label>Max wire distance (km)</Form.Label>
            <Form.Control
              type="text"
              value={maxWiredDistanceKm}
              onChange={(e) => {
                setMaxWiredDistanceKm(e.target.value);
              }}
            />
          </Form.Group>
          <Button
            variant="primary"
            onClick={() => {
              saveSite({ maxWiredDistanceKm: maxWiredDistanceKm });
            }}
            disabled={site.maxWiredDistanceKm === Number(maxWiredDistanceKm)}
            style={{ padding: "0.375rem", width: "100%" }}
            size="sm"
          >
            Save distance
          </Button>
        </div>
      </div>
      {fetchSiteSdmList.isFetching && (
        <Loading height="10vh" label="Computing import/export matches..." />
      )}
      {!fetchSiteSdmList.isFetching && (
        <Table bordered size="sm" style={{ margin: 0 }}>
          <thead>
            <tr style={{ verticalAlign: "top" }}>
              <th colSpan={filterWired ? 3 : 2}></th>
              <th
                colSpan={5}
                onClick={() => {
                  if (sortColumn === "sell") {
                    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
                  } else {
                    setSortDirection("desc");
                    setSortColumn("sell");
                  }
                }}
              >
                Site to sell to:{" "}
                {!filterWired && sortColumn === "sell" && (
                  <img
                    src={
                      sortDirection === "asc"
                        ? Icons.arrowsUpDownUp
                        : Icons.arrowsUpDownDown
                    }
                    alt=">"
                  />
                )}
                {!filterWired && sortColumn !== "sell" && (
                  <img src={Icons.arrowsUpDownNeither} alt=">" />
                )}
              </th>
              <th
                colSpan={5}
                onClick={() => {
                  if (sortColumn === "buy") {
                    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
                  } else {
                    setSortDirection("desc");
                    setSortColumn("buy");
                  }
                }}
              >
                Site to buy from:{" "}
                {!filterWired && sortColumn === "buy" && (
                  <img
                    src={
                      sortDirection === "asc"
                        ? Icons.arrowsUpDownUp
                        : Icons.arrowsUpDownDown
                    }
                    alt=">"
                  />
                )}
                {!filterWired && sortColumn !== "buy" && (
                  <img src={Icons.arrowsUpDownNeither} alt=">" />
                )}
              </th>
            </tr>
            <tr style={{ verticalAlign: "top" }}>
              <th>Name, DNO/Substation</th>
              {filterWired && (
                <th
                  onClick={() =>
                    setSortDirection(sortDirection === "asc" ? "desc" : "asc")
                  }
                >
                  Distance (km)
                  <img
                    src={
                      sortDirection === "asc"
                        ? Icons.arrowsUpDownUp
                        : Icons.arrowsUpDownDown
                    }
                    alt=">"
                  />
                </th>
              )}
              <th>Ownerships</th>
              <th>Amount</th>
              <th>Tariff</th>
              <th>IRR ({isOwnerOccupied ? "OO" : "LL"}, License)</th>
              <th>IRR Δ ({isOwnerOccupied ? "OO" : "LL"}, License)</th>
              <th></th>
              <th>Amount</th>
              <th>Proc. cost (y1)</th>
              <th>
                Savings (
                {isOwnerOccupied
                  ? `OO, ${capitalize(site.activeInvestmentModel)}`
                  : "Tenant"}
                , y1)
              </th>
              <th>
                Savings Δ (
                {isOwnerOccupied
                  ? `OO, ${capitalize(site.activeInvestmentModel)}`
                  : "Tenant"}
                , y1)
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>{sdmRows}</tbody>
        </Table>
      )}
    </Card>
  );
};

export default SdmOffers;
