import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import getOrganisationList from "../http/getOrganisationList";
import postOrganisation from "../http/postOrganisation";

const useOrganisationList = () => {
  const { getToken } = useKindeAuth();
  const queryClient = useQueryClient();

  const fetchOrganisations = useQuery(
    "orgs",
    async () => {
      return getOrganisationList(await getToken());
    },
    {
      // Allow query to be mounted multiple times on the same page without re-fetching
      staleTime: 5 * 60 * 1000,
    }
  );

  const createOrganisation = useMutation(
    async (org) => postOrganisation(await getToken(), org),
    {
      onSuccess: (addedOrg) => {
        // Update the cached list of orgs instead of refetching
        queryClient.setQueryData("orgs", (currentOrgs) => [
          ...currentOrgs,
          addedOrg,
        ]);
      },
    }
  );

  return {
    createOrganisation,
    fetchOrganisations,
  };
};

export default useOrganisationList;
