import axiosConfig from "./axiosConfig";

const getSiteEnergyFlow = async (authToken, siteId, groupByDay, actuals) => {
  const queryArgs = [];
  if (groupByDay) {
    queryArgs.push("groupByDay=true");
  }
  if (actuals) {
    queryArgs.push("actuals=true");
  }
  const response = await axiosConfig.get(
    `/site/${siteId}/site_energy_flows${queryArgs.length > 0 ? `?${queryArgs.join("&")}` : ""}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      responseType: "blob",
    }
  );
  // Create a URL for the blob
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;

  // Set the download attribute with a default file name
  link.setAttribute(
    "download",
    `${siteId}-energy-flow${groupByDay ? "-daily" : ""}${actuals ? "-actuals" : "-forecast"}.csv`
  );

  // Append the link to the body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export default getSiteEnergyFlow;
