import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useMutation, useQueryClient } from "react-query";
import putSite from "../http/putSite";

const useSiteUpdate = () => {
  const { getToken } = useKindeAuth();
  const queryClient = useQueryClient();
  return useMutation(
    async (update: { siteId: string; payload: any }) =>
      putSite(await getToken(), update.siteId, undefined, update.payload),
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(["site", variables.siteId]);
      },
    }
  );
};

export default useSiteUpdate;
