import { BarDatum, ResponsiveBar } from "@nivo/bar";
import React, { useRef } from "react";
import styled from "styled-components";
import { colors, theme } from ".";
import { formatUnits } from "../../formatting/formatKiloUnits";
import {
  ChartToolTip,
  ChartToolTipBody,
  ChartToolTipHeader,
} from "../ChartTooltip";

import Tooltip from "../Tooltip";
import { Chart, ChartLegend, ChartWrapper } from "./common";
import { NonOverflowTooltip } from "./NonOverflowTooltip";

const chartLegend = [
  {
    label: "Generation",
    color: colors[0],
  },
  {
    label: "Demand",
    color: colors[1],
  },
];

interface ResponsiveBarChartProps {
  chartData: BarDatum[];
  keys: string[];
  theme: any;
}

const ResponsiveBarChart: React.FC<ResponsiveBarChartProps> = ({
  chartData,
  keys,
  theme,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const showTooltip = (e) => {
    const eventCoords = e as unknown as { x: number; y: number };
    return (
      <NonOverflowTooltip
        point={{ x: eventCoords.x, y: eventCoords.y }}
        containerSize={ref.current!.getBoundingClientRect()}
      >
        <ChartToolTip>
          <ChartToolTipHeader>{e.data.time}</ChartToolTipHeader>
          <ChartToolTipBody>
            Generation:{" "}
            <strong>
              {formatUnits(
                parseFloat((e.data["Generation"] as number)?.toFixed(1)),
                "kWh"
              )}
            </strong>
          </ChartToolTipBody>
          <ChartToolTipBody>
            Consumption:{" "}
            <strong>
              {formatUnits(
                parseFloat((e.data["Consumption"] as number)?.toFixed(1)),
                "kWh"
              )}
            </strong>
          </ChartToolTipBody>
        </ChartToolTip>
      </NonOverflowTooltip>
    );
  };
  return (
    <ChartContainer ref={ref}>
      <ResponsiveBar
        data={chartData}
        indexBy={"index"}
        groupMode="grouped"
        keys={keys}
        margin={{
          left: 60,
          bottom: 40,
          top: 10,
        }}
        padding={0.3}
        colors={colors}
        colorBy={chartData["index"]}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          tickSize: 0,
          tickPadding: 5,
          legendPosition: "middle",
          legendOffset: -40,
          legend: "kWh",
          format: (value) => {
            return `${value}`;
          },
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 90,
          legendPosition: "middle",
          legendOffset: 32,
        }}
        theme={theme}
        animate={true}
        enableLabel={false}
        tooltip={showTooltip}
      />
    </ChartContainer>
  );
};

interface GenerationVsDemandChartProps {
  chartData: BarDatum[];
  keys: string[];
  height: string;
}

const GenerationVsDemandChart: React.FC<GenerationVsDemandChartProps> = ({
  chartData,
  keys,
  height,
}) => {
  return (
    <ChartWrapper height={height}>
      <Container>
        <MetricTooltip>
          <Tooltip
            text={
              "The energy generation of an on-site installation vs. the on-site demand for that site, broken down on a half-hourly basis for a “typical” summer day.\n\nSubmitted half hourly demand data improves accuracy."
            }
          />
        </MetricTooltip>
        <Header>
          <Title>Generation vs. on-site demand (typical summer day)</Title>
          <ChartLegendContainer>
            <ChartLegend chartLegend={chartLegend} />
          </ChartLegendContainer>
        </Header>
        <Header></Header>
        <Chart>
          <ResponsiveBarChart chartData={chartData} keys={keys} theme={theme} />
        </Chart>
      </Container>
    </ChartWrapper>
  );
};

const MetricTooltip = styled.div`
  align-self: flex-end;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 16px;
  border-radius: 8px;
  padding-top: 0px;
`;

const Title = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #00022f;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ChartContainer = styled.div`
  width: 100%;
  height: 295px;
  flex-grow: 1;
`;

const ChartLegendContainer = styled.div``;

export default GenerationVsDemandChart;
