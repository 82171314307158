import {
  useOrganisation,
  useOrganisationList,
} from "@inrange/building-manager-api-client";
import { Organisation } from "@inrange/building-manager-api-client/models-organisation";
import { useState } from "react";
import { Button, Form, InputGroup, Table } from "react-bootstrap";

const OrgUsers = ({ organisation }: { organisation: Organisation }) => {
  const { addOrgUser, removeOrgUser } = useOrganisation(organisation.id);
  const { fetchOrganisations } = useOrganisationList();
  const [email, setEmail] = useState<string>("");
  const [usersIsRemoving, setUsersIsRemoving] = useState<{
    [key: string]: boolean;
  }>({});

  const onAddUser = (email: string) => {
    addOrgUser.mutate(
      { email },
      {
        onError: (error: any) => {
          alert(error?.response?.data?.message || error);
        },
        onSuccess: () => {
          setUsersIsRemoving({ ...usersIsRemoving, [email]: false });
        },
      }
    );
  };

  const onRemoveUser = (email: string) => {
    setUsersIsRemoving({ ...usersIsRemoving, [email]: true });
    removeOrgUser.mutate(
      { email },
      {
        onError: (error: any) => {
          setUsersIsRemoving({ ...usersIsRemoving, [email]: false });
          alert(error?.response?.data?.message || error);
        },
      }
    );
  };

  const addUser = (email: string) => {
    onAddUser(email);
    setEmail("");
  };

  const orgsById = (fetchOrganisations.data?.organisations || []).reduce(
    (
      acc: { [key: string]: { id: string; name: string } },
      org: { id: string; name: string }
    ) => {
      acc[org.id] = org;
      return acc;
    },
    {}
  );

  const testMode = !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE;

  return (
    <div>
      <h4>Manage users ({organisation.userEmails?.length || 0})</h4>
      {organisation.userEmails?.length > 0 && (
        <>
          <Table bordered style={{ fontSize: 14 }}>
            <thead>
              <tr>
                <th>Email</th>
                <th>Shared by</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {organisation?.userEmails?.map((userEmail) => (
                <tr key={userEmail}>
                  <td>{userEmail}</td>
                  <td>
                    {(
                      organisation?.userEmailPermissions?.[userEmail]
                        ?.sharedByOrgs || []
                    ).map((sharedByOrgDetails, index) => (
                      <>
                        <a
                          href={`/organisation/${sharedByOrgDetails.orgId}/edit`}
                          key={sharedByOrgDetails.orgId}
                        >
                          {orgsById[sharedByOrgDetails.orgId]
                            ? testMode
                              ? orgsById[sharedByOrgDetails.orgId].name.replace(
                                  /-.*/,
                                  " - fixed-id-for-testing"
                                )
                              : orgsById[sharedByOrgDetails.orgId].name
                            : "Loading name ..."}
                        </a>
                        {index < sharedByOrgDetails.length - 1 ? ", " : ""}
                      </>
                    ))}
                  </td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() => onRemoveUser(userEmail)}
                      disabled={usersIsRemoving[userEmail]}
                    >
                      Remove user
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      <InputGroup className="mb-3">
        <Form.Control
          placeholder="Email address"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          variant="primary"
          onClick={() => addUser(email)}
          style={{ marginRight: 10 }}
        >
          Add user
        </Button>
      </InputGroup>
    </div>
  );
};

export default OrgUsers;
