import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { LinkContainer } from "react-router-bootstrap";
import { Outlet } from "react-router-dom";

import { KINDE_ENVIRONMENT } from "@inrange/auth";
import { getApiHostname } from "@inrange/building-manager-api-client/config";
import { InRangeLogoWhite } from "@inrange/theme-components/icons";
import { useContext } from "react";
import { UserContext } from "../auth/UserContext";

function Template() {
  const { user, logout } = useContext(UserContext);

  const apiHostname = getApiHostname();
  const prodApiHostname = "https://main-api.prod.inrange.io";
  const isUsingProdApiHostname = apiHostname === prodApiHostname;

  const domain = window.location.hostname;
  const prodDomain = "admin.inrange.io";
  const isProdDomain = domain === prodDomain;

  return (
    <>
      {!isProdDomain && (
        <Navbar bg={isUsingProdApiHostname ? "warning" : "info"} expand="md">
          <Container>
            <Navbar.Brand>
              {isUsingProdApiHostname
                ? "WARNING: Non-Prod website connecting to Prod API"
                : "INFO: Non-Prod website connecting to Non-Prod API"}
            </Navbar.Brand>
          </Container>
        </Navbar>
      )}
      <Navbar
        expand="md"
        style={{ backgroundColor: "#20124d", padding: "8px 20px" }}
      >
        <Navbar.Brand href="/">
          <img src={InRangeLogoWhite} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/organisation">
              <Nav.Link>Organisations</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/site">
              <Nav.Link>Sites</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/site/new">
              <Nav.Link>New Site</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/reports">
              <Nav.Link>Reports</Nav.Link>
            </LinkContainer>
          </Nav>
          <Navbar>
            <NavDropdown title={user.name} id="basic-nav-dropdown">
              <NavDropdown.Item
                target="_blank"
                href={`https://${KINDE_ENVIRONMENT.KINDE_MANAGEMENT_DOMAIN}/admin/cx/_:nav&m:users::_:submenu&s:list`}
              >
                Manage Access
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => {
                  console.log("User logout");
                  logout();
                }}
              >
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Navbar>
        </Navbar.Collapse>
      </Navbar>
      <Outlet />
    </>
  );
}

export default Template;
