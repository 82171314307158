import {
  Organisation,
  OrgSiteListEntry,
} from "@inrange/building-manager-api-client/models-organisation";
import { Site } from "@inrange/building-manager-api-client/models-site";
import "leaflet/dist/leaflet.css";
import React from "react";
import styled from "styled-components";
import "./map.css";
import { PopupText } from "./marketplace-map-styles";

const ownershipMap: { [key: string]: string } = {
  tenant: "Tenant",
  landlord: "Landlord",
  ownerOccupier: "Owner Occupier",
  agent: "Agent",
  broker: "Broker",
};

const RelatedOwnershipLine: React.FC<{
  org: Organisation;
  site: OrgSiteListEntry | Site;
}> = ({ org, site }) => {
  const orgRelationshipWithSite = site.siteOwnerships.find(
    (ownership) => ownership.orgID === org.id
  )!.ownership;
  if (
    orgRelationshipWithSite !== "landlord" &&
    orgRelationshipWithSite !== "tenant"
  ) {
    return undefined;
  }

  const relatedOwnershipType =
    orgRelationshipWithSite === "landlord" ? "tenant" : "landlord";
  const relatedOwnershipsCount = site.siteOwnerships.filter(
    (ownership) => ownership.ownership === relatedOwnershipType
  ).length;
  if (relatedOwnershipsCount === 0) {
    return undefined;
  }

  const firstRelatedOwnership = site.siteOwnerships.find(
    (ownership) => ownership.ownership === relatedOwnershipType
  )!;
  return (
    <>
      <PopupText style={{ marginTop: "1.33em" }}>
        {firstRelatedOwnership.name!}{" "}
        <SubPopupText>
          {ownershipMap[firstRelatedOwnership.ownership]}
        </SubPopupText>
      </PopupText>
      {relatedOwnershipsCount > 1 && (
        <span>+{relatedOwnershipsCount - 1} more</span>
      )}
    </>
  );
};

const SubPopupText = styled.span`
  font-size: 12px;
  font-weight: normal;
  width: fit-content;
`;

export default RelatedOwnershipLine;
