import { FC, useRef } from "react";
import { colors, colorsWithBattery } from ".";
import BarChart from "./BarChart";

import { ChartToolTip, ChartToolTipBody } from "../ChartTooltip";
import { NonOverflowTooltip } from "./NonOverflowTooltip";

import { BarDatum, BarTooltipProps } from "@nivo/bar";
import styled from "styled-components";
import { formatUnits } from "../../formatting/formatKiloUnits";
import TabButtons from "../buttons/TabButtons";
import { Column } from "../layout/Flex";
import { ChartData } from "./chart-types";
import {
  ChartHeader,
  ResponsiveChartHeightChart,
  ResponsiveChartHeightChartHeaderWithTabs,
  ResponsiveChartHeightChartLegend,
  ResponsiveChartHeightChartWrapper,
  ResponsiveChartHeightRelativeWrapper,
} from "./common";

interface YearDayEnergyChartProps {
  header?: string;
  chartData: ChartData[];
  barColors?: string[];
  legendKeys?: Record<string, string>;
  energyProcuredChartPeriod: "year" | "summer" | "winter";
  setEnergyProcuredChartPeriod: (period: "year" | "summer" | "winter") => void;
  height?: string;
  chartHeight?: string;
  isGeneratingSite: boolean;
  isBatterySite: boolean;
  isNotYetProcuringSite?: boolean;
  buyEnergyBlock?: React.ReactNode;
  $padding?: string;
  tooltipFontSize?: string;
  chartMargins?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
  stacked?: boolean;
}

const YearDayEnergyChart: FC<YearDayEnergyChartProps> = ({
  header,
  chartData,
  barColors,
  legendKeys,
  energyProcuredChartPeriod, // ["year", "summer", "winter"]
  setEnergyProcuredChartPeriod,
  height,
  chartHeight,
  isGeneratingSite,
  isBatterySite,
  isNotYetProcuringSite,
  buyEnergyBlock,
  $padding,
  tooltipFontSize,
  chartMargins,
  stacked,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  if (!barColors) {
    barColors = isBatterySite ? colorsWithBattery : colors;
  }
  if (!legendKeys) {
    legendKeys = Object.keys(chartData[0])
      .filter((key) => key !== "index")
      .reduce(
        (acc, key) => {
          acc[key] = key;
          return acc;
        },
        {} as Record<string, string>
      );
    if (!isGeneratingSite) {
      barColors = colors.slice(1);
      if (isNotYetProcuringSite) {
        barColors![1] = "#cccccc";
      }
      delete legendKeys["On-site behind the meter"];
    }
  }

  const ToolTipData: FC<{ data: BarDatum }> = ({ data }) => {
    return (
      <>
        {Object.keys(legendKeys).map((key, index) => (
          <ChartToolTipBody key={index} $fontSize={tooltipFontSize}>
            {legendKeys[key]}:{" "}
            <strong>
              {formatUnits(
                typeof data[key] === "string"
                  ? parseFloat(data[key])
                  : data[key],
                "kWh"
              )}
            </strong>
          </ChartToolTipBody>
        ))}
      </>
    );
  };

  const showToolTipFn = (e: BarTooltipProps<BarDatum>) => {
    const eventCoords = e as unknown as { x: number; y: number };
    return (
      <NonOverflowTooltip
        point={{ x: eventCoords.x, y: eventCoords.y }}
        containerSize={ref.current!.getBoundingClientRect()}
      >
        <ChartToolTip>
          <ToolTipData data={e.data} />
        </ChartToolTip>
      </NonOverflowTooltip>
    );
  };

  return (
    <ResponsiveChartHeightChartWrapper
      ref={ref}
      height={height}
      $padding={$padding}
    >
      <ResponsiveChartHeightChartHeaderWithTabs>
        <div>
          {header && (
            <>
              <ChartHeader>{header}</ChartHeader>
            </>
          )}
        </div>
        <TabButtons
          tab1={{
            label: "Year 1",
            active: energyProcuredChartPeriod === "year",
            onClick: () => setEnergyProcuredChartPeriod("year"),
          }}
          tab2={{
            label: "Daily (Summer)",
            active: energyProcuredChartPeriod === "summer",
            onClick: () => setEnergyProcuredChartPeriod("summer"),
          }}
          tab3={{
            label: "Daily (Winter)",
            active: energyProcuredChartPeriod === "winter",
            onClick: () => setEnergyProcuredChartPeriod("winter"),
          }}
        />
      </ResponsiveChartHeightChartHeaderWithTabs>
      {Object.keys(legendKeys).length > 1 && (
        <ResponsiveChartHeightChartLegend
          chartLegend={Object.keys(legendKeys).map((key, index) => ({
            label: legendKeys[key],
            color: barColors![index],
          }))}
        />
      )}
      <ResponsiveChartHeightRelativeWrapper>
        <ResponsiveChartHeightChart $height={chartHeight}>
          <BarChart
            chartData={chartData as BarDatum[]}
            barColors={barColors}
            tooltipFn={showToolTipFn}
            keys={Object.keys(legendKeys)}
            legend={"kWh"}
            stacked={stacked === undefined ? true : stacked}
            tickRotationProp={energyProcuredChartPeriod === "year" ? 0 : 90}
            chartMargins={chartMargins}
          />
          {isNotYetProcuringSite && (
            <NonGeneratingSiteOverlay>
              <Column style={{ maxWidth: "570px" }}>
                <span
                  style={{
                    fontSize: "30px",
                    color: "#757575",
                    margin: "0 15px 15px 15px",
                    padding: "0 8px",
                  }}
                >
                  Your site is not yet procuring energy.
                </span>
                {buyEnergyBlock}
              </Column>
            </NonGeneratingSiteOverlay>
          )}
        </ResponsiveChartHeightChart>
      </ResponsiveChartHeightRelativeWrapper>
    </ResponsiveChartHeightChartWrapper>
  );
};

// -54px top to account for the chart legend
const NonGeneratingSiteOverlay = styled(Column)`
  position: absolute;
  top: -54px;
  left: 15%;
  padding-left: 12px;
  width: 70%;
  height: 100%;
  background-color: transparent;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default YearDayEnergyChart;
