import React from "react";
import styled from "styled-components";
import networkSiteBlue from "../../images/icons/networkSiteBlue.svg";
import networkSitePurple from "../../images/icons/networkSitePurple.svg";
import networkSiteYellow from "../../images/icons/networkSiteYellow.svg";
import {
  MARKETPLACE_COLOR_BLUE,
  MARKETPLACE_COLOR_PURPLE,
  MARKETPLACE_COLOR_YELLOW,
} from "./marketplace-map-styles";

interface MapLegendProps {
  mySitesLength: number;
  isShowingSpecificOffer: boolean;
  exploreExistingMatchesInNetwork: number;
  existingMatchesLength: number;
  offerType: string;
}

const MapLegend: React.FC<MapLegendProps> = ({
  mySitesLength,
  isShowingSpecificOffer,
  exploreExistingMatchesInNetwork,
  existingMatchesLength,
  offerType,
}) => {
  return (
    <Container
      data-testid="marketplace-map-legend"
      $height={
        isShowingSpecificOffer || exploreExistingMatchesInNetwork === 0
          ? "130px"
          : "170px"
      }
    >
      <Legend>
        <Title style={{ color: MARKETPLACE_COLOR_PURPLE }}>
          {isShowingSpecificOffer ? "Buying site" : ""}
          {!isShowingSpecificOffer
            ? `Your ${mySitesLength > 1 ? "Sites" : "Site"}`
            : ""}
        </Title>
        <Icon src={networkSitePurple} />
      </Legend>
      <Legend>
        <Title style={{ color: MARKETPLACE_COLOR_BLUE }}>
          {isShowingSpecificOffer ? "Selling site" : ""}
          {!isShowingSpecificOffer ? "InRange Network" : ""}
        </Title>
        <Icon src={networkSiteBlue} />
      </Legend>
      {(exploreExistingMatchesInNetwork > 0 ||
        (isShowingSpecificOffer && existingMatchesLength > 0)) && (
        <Legend>
          <Title style={{ color: MARKETPLACE_COLOR_YELLOW }}>
            {!isShowingSpecificOffer &&
              (offerType === "buy"
                ? "Existing Network Sellers"
                : "Existing Network Buyers")}
            {isShowingSpecificOffer &&
              (offerType === "buy" ? "Existing Sellers" : "Existing Buyers")}
          </Title>
          <Icon src={networkSiteYellow} />
        </Legend>
      )}
    </Container>
  );
};

const Container = styled.div<{ $height: string }>`
  position: absolute;
  right: 8px;
  bottom: 8px;
  margin-top: 8px;
  z-index: 450;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
  width: 150px;
  height: ${(props) => props.$height};
  background: #ffffff;
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
`;
const Legend = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
`;

const Title = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
`;
const Icon = styled.img`
  width: 25px;
  height: 25px;
`;

export default MapLegend;
