import {
  SdmMatchConfig,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import {
  formatCurrency,
  formatPercentage,
} from "@inrange/theme-components/formatting";
import { UseMutationResult, UseQueryResult } from "react-query";
import isSiteOwnerOccupied from "../../../utils/isSiteOwnerOccupied";
import { formatNumber2dp } from "./utils";

const SdmMatchImpactDescription: React.FC<{
  site: Site;
  selectedOffer: SdmMatchConfig;
  isOfferBeingAdded: boolean;
  fetchSiteBuyer: UseQueryResult<{ site: Site }>;
  previewSiteBuyer: UseMutationResult<{ site: Site }>;
  fetchSiteSeller: UseQueryResult<{ site: Site }>;
  previewSiteSeller: UseMutationResult<{ site: Site }>;
}> = ({
  site,
  selectedOffer,
  isOfferBeingAdded,
  fetchSiteBuyer,
  previewSiteBuyer,
  fetchSiteSeller,
  previewSiteSeller,
}) => {
  const getSavings = (site: Site) => {
    const savingsOwnership = isSiteOwnerOccupied(site.siteOwnerships)
      ? "ownerOccupier"
      : "tenant";
    const savingsFinancialModel = site.financialModels[savingsOwnership];
    return savingsOwnership === "ownerOccupier"
      ? savingsFinancialModel[site.activeInvestmentModel]
      : savingsFinancialModel;
  };

  const getIrr = (site: Site) => {
    const irrOwnership = isSiteOwnerOccupied(site.siteOwnerships)
      ? "ownerOccupier"
      : "landlord";
    return site.financialModels[irrOwnership]["license"].irr;
  };

  const buyerMessage = (
    <>
      buyer&apos;s savings by{" "}
      {formatCurrency(
        getSavings(previewSiteBuyer.data!.site).savings -
          getSavings(fetchSiteBuyer.data!.site).savings,
        previewSiteBuyer.data!.site.currencyCode,
        2,
        false,
        true
      )}{" "}
      (Y1) to{" "}
      <b>
        {formatCurrency(
          getSavings(previewSiteBuyer.data!.site).savings,
          previewSiteBuyer.data!.site.currencyCode,
          2,
          false
        )}{" "}
        (Y1),{" "}
        {formatCurrency(
          getSavings(previewSiteBuyer.data!.site).lifetimeSavings,
          previewSiteBuyer.data!.site.currencyCode,
          2,
          false
        )}{" "}
        (Lifetime)
      </b>
    </>
  );
  const sellerMessage = (
    <>
      seller&apos;s IRR by{" "}
      {formatPercentage(
        (getIrr(previewSiteSeller.data!.site) as number) -
          (getIrr(fetchSiteSeller.data!.site) as number),
        2
      )}{" "}
      to{" "}
      <b>
        {formatPercentage(getIrr(previewSiteSeller.data!.site) as number, 2)}
      </b>
    </>
  );

  return (
    <>
      {selectedOffer.buyerId === site.id && (
        <>
          This match will change the {buyerMessage} and the {sellerMessage},{" "}
          {isOfferBeingAdded ? "removing" : "adding"}{" "}
          {formatNumber2dp(selectedOffer.volume)} kWh{" "}
          {isOfferBeingAdded ? "from" : "to"} the buyer&apos;s unmet demand
          excess and the seller&apos;s excess pools.
        </>
      )}
      {selectedOffer.sellerId === site.id && (
        <>
          This match will change the {sellerMessage} and the {buyerMessage},{" "}
          {isOfferBeingAdded ? "removing" : "adding"}{" "}
          {formatNumber2dp(selectedOffer.volume)} kWh{" "}
          {isOfferBeingAdded ? "from" : "to"} the seller&apos;s excess and the
          buyer&apos;s unmet demand pools.
        </>
      )}
    </>
  );
};

export default SdmMatchImpactDescription;
