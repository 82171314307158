import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import LogoutIcon from "../images/icons/logout.svg";
import OrgSwitchIcon from "../images/icons/switcher.svg";
import InRangeLogo from "../images/inrange-logo.svg";

const Sidebar = ({
  currentUrl,
  links,
  onClickLogout,
  onOrgSwitch,
  userOrgs = [],
  currentKindeOrg,
  width = 220,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSwitchOrg, setIsSwitchOrg] = useState(false);

  userOrgs = userOrgs.sort((a, b) =>
    a.name.toUpperCase().localeCompare(b.name.toUpperCase())
  );

  return (
    <SidebarContainer
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => {
        setIsOpen(false);
        setIsSwitchOrg(false);
      }}
      $width={width}
    >
      <LogoWrapper>
        <img src={InRangeLogo} alt="InRange Logo" />
      </LogoWrapper>
      <LinkWrapper>
        <TopItems>
          {links.map((link, index) => (
            <SidebarItem
              key={index}
              currentUrl={currentUrl}
              url={link.url}
              icon={link.icon}
              label={link.label}
              isOpen={isOpen}
              width={width}
            />
          ))}
        </TopItems>
        <BottomItems>
          {userOrgs.length > 1 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <SidebarItem
                currentUrl={currentUrl}
                icon={OrgSwitchIcon}
                label={"Switch org."}
                isOpen={isOpen}
                isNotLastItem={true}
                onClick={() => setIsSwitchOrg(!isSwitchOrg)}
                width={width}
              />
              {isSwitchOrg && isOpen && (
                <SwitchOrgWrapper>
                  {userOrgs.map((item) => {
                    const thisOrg = item.id === currentKindeOrg;
                    return (
                      <SwitchOrgItem
                        onClick={() => onOrgSwitch(item.id)}
                        key={item.id}
                        thisOrg={thisOrg}
                        disabled={thisOrg}
                      >
                        {item.name}
                        {thisOrg ? " (this org)" : null}
                      </SwitchOrgItem>
                    );
                  })}
                </SwitchOrgWrapper>
              )}
            </div>
          )}
          <SidebarItem
            currentUrl={currentUrl}
            url={"#"}
            icon={LogoutIcon}
            label={"Log out"}
            isOpen={isOpen}
            isNotLastItem={true}
            onClick={onClickLogout}
            width={width}
          />
        </BottomItems>
      </LinkWrapper>
    </SidebarContainer>
  );
};

const SidebarItem = ({
  currentUrl,
  url,
  icon,
  label,
  isOpen,
  isNotLastItem,
  onClick,
  width,
}) => {
  let isActive = currentUrl === url;
  if (label === "Leased Sites" && currentUrl.includes("dashboard"))
    isActive = true;
  return (
    <SidebarLink
      to={url}
      $active={isActive}
      $isNotLastItem={isNotLastItem}
      onClick={onClick}
      $width={width}
    >
      <SidebarIcon src={icon} />
      {isOpen && <SidebarLinkText>{label}</SidebarLinkText>}
    </SidebarLink>
  );
};

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 100%;
  &:hover {
    width: ${(props) => props.$width || 220}px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 28px;
  padding-left: 10px;
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-left: 16px;
  width: 188px;
  padding: 4px;
  flex-grow: 1;
`;

const TopItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 50%;
`;

const BottomItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 50%;
  justify-content: flex-end;
`;

const SwitchOrgWrapper = styled.div`
  margin-left: 16px;
  padding: 8px;
  width: 200px;
  position: fixed;
  max-height: 300px;
  overflow-y: auto;
  background: white;
  left: 135px;
  bottom: 61px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const SwitchOrgItem = styled.li`
  font-size: 14px;
  color: ${({ theme, thisOrg }) => (thisOrg ? "grey" : theme.colors.dark)};
  cursor: ${({ thisOrg }) => (thisOrg ? "default" : "pointer")};
  border-radius: 4px;
  margin-left: 4px;
  margin-top: 4px;
  padding: 4px;
  list-style-type: none;
  &:hover {
    background: #efefef;
  }
`;

const SidebarLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 44px;
  width: 100%;
  border-radius: 8px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.dark};
  ${({ $active, theme }) =>
    $active && {
      backgroundColor: theme.colors.grey200,
    }}
  &:hover {
    width: ${(props) => props.$width - 20 || 200}px;
  }
`;

const SidebarIcon = styled.img`
  width: 22px;
  height: 22px;
  margin: 0 12px;
`;

const SidebarLinkText = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

export default Sidebar;
