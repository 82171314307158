import {
  AggregateEnergyFlow,
  Site,
} from "@inrange/building-manager-api-client/models-site";
import { formatUnits } from "@inrange/theme-components/formatting";

export const getCurtailmentDetails = (
  site: Site,
  energyFlowAnnual: AggregateEnergyFlow
): [string | undefined, string, string] => {
  let curtailment: string | undefined = undefined;
  let curtailmentRisk = "unknown";
  let curtailmentColor = "orange";
  if (site.installedCapacity === 0) {
    // Procure only site, no curtailment
    curtailment = "N/A";
    curtailmentColor = "inherit";
  } else if (site.exportLimit !== null && site.exportLimit !== undefined) {
    // Known export limit, known curtailment
    if (energyFlowAnnual.curtailed === 0) {
      curtailment = `no`;
      curtailmentColor = "inherit";
    } else if (energyFlowAnnual.curtailed < 10_000) {
      curtailment = `${formatUnits(energyFlowAnnual.curtailed, "kWh", 2)}`;
      curtailmentColor = "inherit";
    } else {
      curtailment = `${formatUnits(energyFlowAnnual.curtailed, "kWh", 2)}`;
      curtailmentColor = "red";
    }
  } else if (site.substationData?.success) {
    // Known generation headroom, known curtailment risk
    if (
      // generationHeadroomKva is in kVA, we need to convert to kWh per half hour
      site.substationData.generationHeadroomKva / 2 >
      site.energyFlowAnnual.maxExport
    ) {
      curtailmentRisk = "no";
      curtailmentColor = "inherit";
    } else {
      curtailmentRisk = `Max ${formatUnits(site.energyFlowAnnual.maxExport - site.substationData.generationHeadroomKva / 2, "kWh", 2)} per half hour`;
      curtailmentColor = "red";
    }
  }
  return [curtailment, curtailmentRisk, curtailmentColor];
};
