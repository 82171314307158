import { useSite } from "@inrange/building-manager-api-client";
import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Loading from "../Loading";
import SiteNav from "./siteSections/SiteNav";

const SiteDebug = () => {
  const [buyerSiteId, setBuyerSiteId] = useState<string>("");
  const [sellerSiteId, setSellerSiteId] = useState<string>("");
  const { siteId } = useParams<{ siteId: string }>();
  const {
    fetchSite,
    fetchSiteEnergyFlow,
    fetchSiteSdmEnergyFlow,
    fetchSiteCashflow,
  } = useSite({
    siteId,
    app: "admin",
  });
  if (!fetchSite.isLoading && fetchSite.isError) {
    return <div>Error loading the site.</div>;
  }

  if (fetchSite.isLoading || !fetchSite.data?.site) {
    return <Loading label="Loading site data..." />;
  }

  return (
    <>
      <SiteNav site={fetchSite.data?.site} />
      <Container fluid style={{ padding: "12px 12px 0px 12px" }}>
        <Row className="mt-3">
          <Col sm={4} className="d-grid">
            <Button
              onClick={() =>
                fetchSiteEnergyFlow.mutate({
                  groupByDay: false,
                  actuals: false,
                })
              }
            >
              Download forecast site energy flow (HH)
            </Button>
          </Col>
          <Col sm={4} className="d-grid">
            <Button
              onClick={() =>
                fetchSiteEnergyFlow.mutate({ groupByDay: true, actuals: false })
              }
            >
              Download forecast site energy flow (Daily)
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={4} className="d-grid">
            <Button
              onClick={() =>
                fetchSiteEnergyFlow.mutate({ groupByDay: false, actuals: true })
              }
            >
              Download actuals site energy flow (HH)
            </Button>
          </Col>
          <Col sm={4} className="d-grid">
            <Button
              onClick={() =>
                fetchSiteEnergyFlow.mutate({ groupByDay: true, actuals: true })
              }
            >
              Download actuals site energy flow (Daily)
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <hr />
        </Row>
        <Row className="mt-3">
          <Col sm={4} className="d-grid">
            <Button
              onClick={() =>
                fetchSiteCashflow.mutate({
                  siteName: fetchSite.data.site.name,
                  cashflowType: "landlord-lease",
                })
              }
            >
              Download cashflow (Landlord, Lease)
            </Button>
          </Col>
          <Col sm={4} className="d-grid">
            <Button
              onClick={() =>
                fetchSiteCashflow.mutate({
                  siteName: fetchSite.data.site.name,
                  cashflowType: "landlord-license",
                })
              }
            >
              Download cashflow (Landlord, License)
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm={4} className="d-grid">
            <Button
              onClick={() =>
                fetchSiteCashflow.mutate({
                  siteName: fetchSite.data.site.name,
                  cashflowType: "ownerOccupier-lease",
                })
              }
            >
              Download cashflow (OO, Lease)
            </Button>
          </Col>
          <Col sm={4} className="d-grid">
            <Button
              onClick={() =>
                fetchSiteCashflow.mutate({
                  siteName: fetchSite.data.site.name,
                  cashflowType: "ownerOccupier-license",
                })
              }
            >
              Download cashflow (OO, License)
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <hr />
        </Row>
        <Row>
          <Col sm={4} className="d-grid">
            <Form.Group className="mb-3" controlId="buyerId">
              <Form.Label>Buyer ID</Form.Label>
              <Form.Control
                type="text"
                value={buyerSiteId}
                onChange={(e) => setBuyerSiteId(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col sm={4} className="d-grid">
            <Form.Group className="mb-3" controlId="sellerId">
              <Form.Label>Seller ID</Form.Label>
              <Form.Control
                type="text"
                value={sellerSiteId}
                onChange={(e) => setSellerSiteId(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className="d-grid">
            <Button
              onClick={() =>
                fetchSiteSdmEnergyFlow.mutate({
                  buyerSiteId,
                  sellerSiteId,
                  groupByDay: false,
                })
              }
            >
              Download SDM energy flow (HH)
            </Button>
          </Col>
          <Col sm={4} className="d-grid">
            <Button
              onClick={() =>
                fetchSiteSdmEnergyFlow.mutate({
                  buyerSiteId,
                  sellerSiteId,
                  groupByDay: true,
                })
              }
            >
              Download SDM energy flow (Daily)
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SiteDebug;
