import { Modal, ModalView } from "@inrange/theme-components";
import Button from "react-bootstrap/Button";

const SaveWarningsModal = ({
  saveWarnings,
  setSaveWarnings,
  updateHubSpotFromModal,
  saveSite,
  setSaveDisabled,
}: {
  saveWarnings: string[];
  setSaveWarnings: (warnings: string[] | undefined) => void;
  updateHubSpotFromModal: boolean;
  saveSite: (updateHubSpot: boolean) => void;
  setSaveDisabled: (disabled: boolean) => void;
}) => {
  return (
    <Modal>
      <ModalView
        title={`Are you sure you want to save?`}
        fontWeight="500"
        fontSize="18px"
        titlePadding="0 0 10px 0"
      >
        <p>{`Warning${saveWarnings.length > 1 ? "s" : ""}:`}</p>
        <ul>
          {saveWarnings.map((warning, index) => (
            <li key={index}>{warning}</li>
          ))}
        </ul>
        <div>Do you want to continue?</div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "30px",
          }}
        >
          <Button
            variant="inrangesecondary"
            onClick={() => {
              setSaveWarnings(undefined);
              setSaveDisabled(false);
            }}
            style={{ width: "160px" }}
          >
            No, go back
          </Button>
          <Button
            variant="success"
            onClick={() => {
              saveSite(updateHubSpotFromModal);
              setSaveWarnings(undefined);
            }}
            style={{ width: "160px" }}
          >
            Yes, save anyway
          </Button>
        </div>
      </ModalView>
    </Modal>
  );
};

export default SaveWarningsModal;
