import styled from "styled-components";

interface FlexProps {
  $justifyContent?: string;
  $alignItems?: string;
  $margin?: string;
  $width?: string;
  $maxWidth?: string;
  $height?: string;
  $padding?: string;
  $gap?: string;
}

export const FlexNoDefaults = styled.div<FlexProps>`
  display: flex;
  ${(props) =>
    props.$justifyContent &&
    `
    justify-content: ${props.$justifyContent};
  `}
  ${(props) =>
    props.$alignItems &&
    `
    align-items: ${props.$alignItems};
  `}
  ${(props) =>
    props.$margin &&
    `
    margin: ${props.$margin};
  `}
  ${(props) =>
    props.$width &&
    `
    width: ${props.$width};
  `}
  ${(props) =>
    props.$maxWidth &&
    `
    max-width: ${props.$maxWidth};
  `}
  ${(props) =>
    props.$height &&
    `
    height: ${props.$height};
  `}
`;

const Flex = styled(FlexNoDefaults)<FlexProps>`
  ${(props) => `
    padding: ${props.$padding || "0 8px"};
    gap: ${props.$gap || "8px"};
    justify-content: ${props.$justifyContent || "flex-start"};
  `}
  ${(props) =>
    props.$height &&
    `
    height: ${props.$height};
  `}
`;

export const Column = styled(Flex)<FlexProps>`
  flex-direction: column;
`;

export const ColumnNoDefaults = styled(FlexNoDefaults)<FlexProps>`
  flex-direction: column;
`;

export const Row = styled(Flex)<FlexProps>`
  flex-direction: row;
  ${(props) => `
    margin: ${props.$margin || "8px 16px 16px"};
  `}
`;

export const RowNoDefaults = styled(FlexNoDefaults)<FlexProps>`
  flex-direction: row;
`;
