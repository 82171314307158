import { BarDatum } from "@nivo/bar";
import React, { useRef } from "react";
import styled from "styled-components";
import { currencySymbol } from "../../formatting/currency-utils";
import formatCurrency from "../../formatting/formatCurrency";
import { formatUnits } from "../../formatting/formatKiloUnits";
import {
  ChartToolTip,
  ChartToolTipBody,
  ChartToolTipHeader,
} from "../ChartTooltip";
import { Column } from "../layout/Flex";
import Tooltip from "../Tooltip";
import BarChart from "./BarChart";
import { ChartData } from "./chart-types";
import { Chart, ChartHeader, ChartLegend, ChartWrapper } from "./common";
import { NonGeneratingSiteOverlay } from "./NonGeneratingSiteOverlay";
import { NonOverflowTooltip } from "./NonOverflowTooltip";

const colors = ["#00022F", "#2779A7", "#2A2C76"];
const nonGeneratingColors = ["#2779A7", "#2A2C76"];

interface ProposalSiteChartProps {
  header: string;
  chartData: ChartData[];
  toolTipText: string;
  currency: boolean;
  currencyCode: string;
  keys: string[];
  isGeneratingSite: boolean;
  isNotYetProcuringSite: boolean;
  buyEnergyBlock: React.ReactNode;
  height: string;
  barColors?: string[];
}

const ProposalSiteChart: React.FC<ProposalSiteChartProps> = ({
  header,
  chartData,
  toolTipText,
  currency,
  currencyCode,
  keys,
  isGeneratingSite,
  isNotYetProcuringSite,
  buyEnergyBlock,
  height,
  barColors,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  if (!barColors) {
    barColors = isGeneratingSite ? colors : nonGeneratingColors;
  }
  const chartLegend = keys.map((key, index) => ({
    label: key,
    color: barColors![index],
  }));

  const showProposalSiteChartTooltip = (
    e: {
      data: BarDatum;
    },
    keys: string[],
    currency: boolean,
    currencyCode: string
  ) => {
    const headerText = e.data["index"];
    const eventCoords = e as unknown as { x: number; y: number };
    return (
      <NonOverflowTooltip
        point={{ x: eventCoords.x, y: eventCoords.y }}
        containerSize={ref.current!.getBoundingClientRect()}
      >
        <ChartToolTip>
          <ChartToolTipHeader>{headerText}</ChartToolTipHeader>
          <MonthlyTooltip
            data={e.data}
            keys={keys}
            currency={currency}
            currencyCode={currencyCode}
          />
        </ChartToolTip>
      </NonOverflowTooltip>
    );
  };

  return (
    <ChartWrapper ref={ref} height={height} $padding={"15px 0 0 0"}>
      <ProposalChartHeader>
        <ChartHeader>{header}</ChartHeader>
        <Tooltip text={toolTipText} position={"bottomLeft"} />
      </ProposalChartHeader>
      <Chart>
        <ChartLegend chartLegend={chartLegend} />
        <BarChart
          chartData={chartData as BarDatum[]}
          tooltipFn={(e) =>
            showProposalSiteChartTooltip(e, keys, currency, currencyCode)
          }
          keys={keys}
          paddingProp={0.3}
          tickRotationProp={0}
          barColors={barColors.slice(0, keys.length)}
          stacked={true}
          legend={currency ? currencySymbol(currencyCode) : "kWh"}
        />
        {isNotYetProcuringSite && (
          <NonGeneratingSiteOverlay>
            <Column style={{ maxWidth: "570px" }}>
              <span
                style={{
                  fontSize: "30px",
                  color: "#757575",
                  margin: "0 15px 15px 15px",
                  padding: "0 8px",
                }}
              >
                Your site is not yet procuring energy.
              </span>
              {buyEnergyBlock}
            </Column>
          </NonGeneratingSiteOverlay>
        )}
      </Chart>
    </ChartWrapper>
  );
};

export default ProposalSiteChart;

interface MonthlyTooltipProps {
  data: BarDatum;
  keys: string[];
  currency: boolean;
  currencyCode: string;
}

const MonthlyTooltip: React.FC<MonthlyTooltipProps> = ({
  data,
  keys,
  currency,
  currencyCode,
}) => {
  const formatValue = (key: string) => {
    const value =
      data[key] !== undefined
        ? Math.round(parseFloat(data[key].toString()) / 10) * 10
        : 0;
    return (
      <strong>
        {currency
          ? formatCurrency(value, currencyCode, 0)
          : formatUnits(value, "kWh")}
      </strong>
    );
  };

  return (
    <>
      {keys.map((key) => (
        <ChartToolTipBody key={key}>
          <span>{key + ": "}</span>
          {formatValue(key)}
        </ChartToolTipBody>
      ))}
    </>
  );
};

const ProposalChartHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 16px;
`;
