import { useOrganisationList } from "@inrange/building-manager-api-client";
import { formatPercentage } from "@inrange/theme-components/formatting";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { Site } from "@inrange/building-manager-api-client/models-site";
import {
  SiteCalculationsContext,
  useContextTS,
} from "@inrange/shared-components";
import { useEffect } from "react";
import { currencyValue } from "./utils";

const FinancialReturns = ({
  site,
  currencyCode,
  isOwnerOccupied,
}: {
  site: Site;
  currencyCode: string;
  isOwnerOccupied: boolean;
}) => {
  const { siteCalculations, setClientsideValidations } = useContextTS(
    SiteCalculationsContext
  );
  const { fetchOrganisations } = useOrganisationList();
  const orgsById = (fetchOrganisations.data?.organisations || []).reduce(
    (
      acc: { [key: string]: { minIRR?: number } },
      org: { id: string; minIRR?: number }
    ) => {
      acc[org.id] = org;
      return acc;
    },
    {}
  );
  const minIRR = (site.siteOwnerships || [])
    .filter(
      (so) =>
        (so.ownership === "landlord" || so.ownership === "ownerOccupier") &&
        orgsById[so.orgID] !== undefined &&
        orgsById[so.orgID].minIRR !== undefined
    )
    .map((so) => orgsById[so.orgID].minIRR)
    .reduce((acc, minIRR) => {
      if (acc === undefined) {
        return minIRR;
      }
      // If there are multiple minIRR values, return the highest one
      return minIRR > acc ? minIRR : acc;
    }, undefined);
  const ownershipKey = isOwnerOccupied ? "ownerOccupier" : "landlord";
  const isIRROutOfBounds =
    site.installedCapacity > 0 &&
    minIRR &&
    (!siteCalculations.financialModels[ownershipKey]?.license?.irr ||
      siteCalculations.financialModels[ownershipKey]?.license?.irr < minIRR);
  useEffect(() => {
    setClientsideValidations((prev) => ({
      ...prev,
      isIRROutOfBounds: isIRROutOfBounds,
    }));
  }, [isIRROutOfBounds, setClientsideValidations]);
  return (
    <Card body className="mt-2">
      <Form.Label>
        <strong>Customer financial returns</strong>
      </Form.Label>
      <Row>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="financialModelIrr">
            <Form.Label>IRR (%)</Form.Label>
            <Form.Control
              disabled
              value={
                siteCalculations.financialModels[ownershipKey]?.license?.irr ||
                "N/A"
              }
              isInvalid={isIRROutOfBounds}
              data-testid="financial-model-irr"
            />
            <Form.Control.Feedback type="invalid">
              {isIRROutOfBounds
                ? `IRR must be at least ${formatPercentage(minIRR, 2)}`
                : undefined}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="paybackMonths">
            <Form.Label>Payback (months)</Form.Label>
            <Form.Control
              disabled
              value={
                siteCalculations.financialModels[ownershipKey]?.license
                  ?.paybackMonths || "N/A"
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="licenseRevenueY1">
            <Form.Label>License revenue (Y1)</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.financialModels[ownershipKey]?.license
                  ?.revenue || 0,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="licenseRevenueLT">
            <Form.Label>License revenue (LT)</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.financialModels[ownershipKey]?.license
                  ?.lifetimeRevenue || 0,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        {ownershipKey === "landlord" && (
          <Col>
            <Form.Group className="mb-3" controlId="tenantSavingsY1">
              <Form.Label>Tenant savings (Y1)</Form.Label>
              <Form.Control
                disabled
                value={currencyValue(
                  siteCalculations.financialModels.tenant?.savings || 0,
                  currencyCode
                )}
              />
            </Form.Group>
          </Col>
        )}
        {ownershipKey === "ownerOccupier" && (
          <Col>
            <Form.Group className="mb-3" controlId="ooSavingsY1">
              <Form.Label>License OO savings (Y1)</Form.Label>
              <Form.Control
                disabled
                value={currencyValue(
                  siteCalculations.financialModels[ownershipKey]?.license
                    ?.savings || 0,
                  currencyCode
                )}
              />
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="leaseReveueY1">
            <Form.Label>Lease revenue (Y1)</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.financialModels[ownershipKey]?.lease
                  ?.revenue || 0,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-3" controlId="leaseRevenueLT">
            <Form.Label>Lease revenue (LT)</Form.Label>
            <Form.Control
              disabled
              value={currencyValue(
                siteCalculations.financialModels[ownershipKey]?.lease
                  ?.lifetimeRevenue || 0,
                currencyCode
              )}
            />
          </Form.Group>
        </Col>
        {ownershipKey === "ownerOccupier" && (
          <Col>
            <Form.Group className="mb-3" controlId="leaseOOSavingsY1">
              <Form.Label>Lease OO savings (Y1)</Form.Label>
              <Form.Control
                disabled
                value={currencyValue(
                  siteCalculations.financialModels[ownershipKey]?.lease
                    ?.savings || 0,
                  currencyCode
                )}
              />
            </Form.Group>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default FinancialReturns;
