import styled from "styled-components";

export const PopupTextLine = styled.div`
  font-size: 12px;
`;

export const PopupTextHighlighted = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

export const MARKETPLACE_COLOR_BLUE = "#2779a7";
export const MARKETPLACE_COLOR_PURPLE = "#a2a6ff";
export const MARKETPLACE_COLOR_YELLOW = "#DBA507";

export const PopupSubHeading = styled.h4<{
  $color: string;
}>`
  color: ${({ $color }) => $color};
  margin-bottom: 0;
`;

export const PopupText = styled.div<{
  $fontSize?: string;
  $fontWeight?: string | number;
  $clickable?: boolean;
}>`
  font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : "16px")};
  font-weight: ${({ $fontWeight }) => ($fontWeight ? $fontWeight : 700)};
  cursor: ${({ $clickable }) => ($clickable ? "pointer" : "default")};
  width: fit-content;
`;

// We need this to override some leaflet styles
export const ForceWhiteText = styled.div`
  span {
    color: #fff !important;
  }
`;
