import { useOrganisation } from "@inrange/building-manager-api-client";
import { Organisation } from "@inrange/building-manager-api-client/models-organisation";
import { Site } from "@inrange/building-manager-api-client/models-site";
import { formatDateTimeFromSeconds } from "@inrange/theme-components";
import { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import getCustomerAppDomain from "../../utils/getCustomerAppDomain";
import Loading from "../Loading";
import MapOfSites from "./MapOfSites";
import OrgParks from "./OrgParks";
import OrgSettingsForm from "./OrgSettingsForm";
import OrgSitesTable from "./OrgSitesTable";
import OrgUsers from "./OrgUsers";

const getSiteOwnership = (site: Site, organisation: Organisation) => {
  return site.siteOwnerships.find(
    (siteOwnership) => siteOwnership.orgID === organisation.id
  );
};

const OrgEdit = () => {
  const { orgId } = useParams<{ orgId: string }>();
  const { fetchOrganisation, updateOrganisation, useOrganisationSites } =
    useOrganisation(orgId);

  const organisation = fetchOrganisation.data?.organisation;
  const sites = useOrganisationSites(organisation?.siteOwnerships);

  const [filteredParks, setFilteredParks] = useState<string[]>([]);

  const onSave = (payload: any) => {
    const update = {
      organisation: payload,
    };
    updateOrganisation.mutate(update);
  };

  if (updateOrganisation.isError) {
    alert(`Error updating org - ${(updateOrganisation.error as any).message}`);
    updateOrganisation.reset();
  }

  if (updateOrganisation.isSuccess) {
    updateOrganisation.reset();
  }

  // Check if the site data is loading
  const anyOrgSitesLoading = sites
    .map((orgSite) => orgSite.isLoading)
    .some((bool) => bool);

  if (fetchOrganisation.isLoading || !organisation) {
    return <Loading label="Loading organisation ..." />;
  }

  if (anyOrgSitesLoading) {
    return <Loading label={`Loading site data for ${organisation?.name}...`} />;
  }

  const errorSites = sites.filter((site) => site.isError);
  if (errorSites.length > 0) {
    return (
      <div>
        {`Failed to load ${errorSites.length} of ${sites.length} sites`}
      </div>
    );
  }

  const successfulSites = sites
    .filter((site) => site.isSuccess)
    .map((site) => site.data?.site)
    .sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

  const filteredSites = successfulSites.filter(
    (site) =>
      filteredParks.length === 0 ||
      filteredParks.indexOf(getSiteOwnership(site, organisation)?.park || "") >
        -1
  );

  document.title = `Organisation: ${organisation?.name}`;

  const testMode = !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE;
  const updateAtStr = testMode
    ? "fixed date for test"
    : formatDateTimeFromSeconds(organisation?.updatedAt);

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <div>
          <p className="text-muted mt-4">Updated: {updateAtStr}</p>
          <h1 className="mt-0">{organisation?.name}</h1>
        </div>
        <a
          href={`${getCustomerAppDomain()}/org/${organisation?.id}/dashboard`}
          target="_blank"
          rel="noreferrer"
          className="mt-4"
        >
          <Button size="lg" variant="success">
            Public portfolio
          </Button>
        </a>
      </div>
      <OrgSettingsForm
        organisation={organisation}
        handleSubmit={onSave}
        isDisableSubmit={updateOrganisation.isLoading}
      />

      <OrgSitesTable
        sites={filteredSites}
        organisation={organisation}
        setFilteredParks={setFilteredParks}
      />
      <OrgUsers organisation={organisation} />
      {organisation.orgType === "Landlord" && (
        <OrgParks organisation={organisation} sites={successfulSites} />
      )}
      <MapOfSites sites={filteredSites} />
    </Container>
  );
};

export default OrgEdit;
