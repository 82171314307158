import React from "react";
import styled from "styled-components";

export const ChartWrapper = styled.div<{ height?: string; $padding?: string }>`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.$padding || "16px 0px 0px 0px"};
  margin: 0;
  background: #ffffff;
  border-radius: 8px;
  height: ${(props) => props.height || "400px"};
  flex-basis: ${(props) => props.height || "400px"};
`;

export const ChartHeader = styled.div`
  max-width: 504px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #00022f;
  padding: 0 16px 16px;
  label {
    display: block;
    font-size: 12px;
  }
`;

export const ChartHeaderWithTabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 16px;
`;

export const Chart = styled.div<{ $height?: string }>`
  height: ${(props) => props.$height || "75%"};
  width: calc(100% - 16px);
`;

export const ChartLegendWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 16px 8px;
  flex-grow: 1;
`;

export const ChartLegendItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 16px;
  align-items: start;
`;

export const ChartLegendItemColor = styled.div<{ color: string }>`
  height: 8px;
  width: 16px;
  background-color: ${(props) => props.color};
  border-radius: 2;
  margin-top: 6px;
`;

export const ChartLegendItemLabel = styled.div`
  padding-left: 5px;
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #1c1c1c;
`;

export const ChartLegend: React.FC<{
  chartLegend: { color: string; label: string }[];
}> = ({ chartLegend }) => {
  return (
    <ChartLegendWrapper>
      {chartLegend.map((legend, index) => (
        <ChartLegendItem key={index}>
          <ChartLegendItemColor color={legend.color}></ChartLegendItemColor>
          <ChartLegendItemLabel>{legend.label}</ChartLegendItemLabel>
        </ChartLegendItem>
      ))}
    </ChartLegendWrapper>
  );
};

// https://github.com/plouc/nivo/issues/867#issuecomment-1303761252
export const ResponsiveChartHeightChartWrapper = styled.div<{
  height?: string;
  $padding?: string;
}>`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.$padding || "16px 0px 0px 0px"};
  margin: 0;
  background: #ffffff;
  border-radius: 8px;
  height: ${(props) => props.height || "400px"};
  flex-basis: ${(props) => props.height || "400px"};
  padding-right: 16px;
`;

export const ResponsiveChartHeightChartHeaderWithTabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ResponsiveChartHeightRelativeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ResponsiveChartHeightChart = styled.div<{ $height?: string }>`
  height: ${(props) => props.$height || "75%"};
  width: calc(100% - 16px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const ResponsiveChartHeightChartLegendWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 16px 8px;
`;

export const ResponsiveChartHeightChartLegend: React.FC<{
  chartLegend: { color: string; label: string }[];
}> = ({ chartLegend }) => {
  return (
    <ResponsiveChartHeightChartLegendWrapper>
      {chartLegend.map((legend, index) => (
        <ChartLegendItem key={index}>
          <ChartLegendItemColor color={legend.color}></ChartLegendItemColor>
          <ChartLegendItemLabel>{legend.label}</ChartLegendItemLabel>
        </ChartLegendItem>
      ))}
    </ResponsiveChartHeightChartLegendWrapper>
  );
};
