import { BarDatum, BarTooltipProps, ResponsiveBar } from "@nivo/bar";
import { FC } from "react";
import { colors, theme } from ".";

interface BarChartProps {
  chartData: BarDatum[];
  keys: string[];
  tooltipFn: (props: BarTooltipProps<BarDatum>) => JSX.Element;
  paddingProp?: number;
  tickRotationProp?: number;
  barColors?: string[];
  legend?: string;
  stacked?: boolean;
  chartMargins?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
}

const BarChart: FC<BarChartProps> = ({
  chartData,
  keys,
  tooltipFn,
  paddingProp,
  tickRotationProp,
  barColors,
  legend,
  stacked,
  chartMargins,
}) => {
  let tickRotation = tickRotationProp;
  if (tickRotation === undefined) tickRotation = 0;
  let padding = paddingProp;
  if (padding === undefined) padding = 0.3;
  if (barColors === undefined) barColors = colors;

  return (
    <ResponsiveBar
      data={chartData}
      indexBy={"index"}
      groupMode={stacked ? "stacked" : "grouped"}
      keys={keys}
      margin={
        chartMargins
          ? chartMargins
          : {
              left: 70,
              bottom: 60,
              top: 10,
            }
      }
      padding={padding}
      colors={barColors}
      colorBy={chartData["index"]}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        tickSize: 0,
        tickPadding: 1,
        tickRotation: 0,
        legendPosition: "middle",
        legendOffset: -50,
        legend: legend,
      }}
      axisBottom={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: tickRotation,
        legendPosition: "middle",
        legendOffset: 32,
      }}
      theme={theme}
      animate={true}
      tooltip={tooltipFn}
      enableLabel={false}
    />
  );
};

export default BarChart;
