import { useSite } from "@inrange/building-manager-api-client";
import {
  ChangeToSiteDefaults,
  PartialSiteAllowStringValues,
  SiteAllowStringValues,
} from "@inrange/building-manager-api-client/models-site";
import { sortBy } from "@inrange/calculations/utils";
import { Modal, ModalView } from "@inrange/theme-components";
import { Button, Table } from "react-bootstrap";
import { formatFieldName } from "./siteSections/ActivityLogMap";

const ConfirmChangesModal = ({
  site,
  setSite,
  pendingUpdateSite,
  setPendingUpdateSite,
}: {
  site: SiteAllowStringValues;
  setSite: (site: PartialSiteAllowStringValues) => void;
  pendingUpdateSite: ChangeToSiteDefaults[];
  setPendingUpdateSite: (updates: ChangeToSiteDefaults[] | undefined) => void;
}) => {
  return (
    <Modal>
      <ModalView
        title={`Would you like to apply these new defaults?`}
        fontWeight="500"
        fontSize="18px"
        titlePadding="0 0 10px 0"
        width="60%"
      >
        {pendingUpdateSite.map((update, index) => (
          <PendingChangesTable
            key={index}
            site={site}
            pendingUpdateSite={update}
          />
        ))}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Button
            variant="inrangesecondary"
            onClick={() => {
              setPendingUpdateSite(undefined);
            }}
            style={{ width: "200px" }}
          >
            No, discard changes
          </Button>
          <Button
            variant="success"
            onClick={() => {
              for (const update of pendingUpdateSite) {
                setSite(update.after);
              }
              setPendingUpdateSite(undefined);
            }}
            style={{ width: "200px" }}
          >
            Yes, apply changes
          </Button>
        </div>
      </ModalView>
    </Modal>
  );
};

const PendingChangesTable = ({
  site,
  pendingUpdateSite,
}: {
  site: SiteAllowStringValues;
  pendingUpdateSite: ChangeToSiteDefaults;
}) => {
  const { LOSS_FACTORS_BY_KEY: lossFactorsByKey } = useSite({
    siteId: site.id,
    app: "admin",
  });
  const flattenObject = (result: object, keyPrefix: string, value: any) => {
    if (typeof value === "object") {
      for (const key in value) {
        flattenObject(
          result,
          `${keyPrefix}${keyPrefix.length === 0 ? "" : "."}${key}`,
          value[key]
        );
      }
    } else {
      result[keyPrefix] = value;
    }
    return result;
  };
  const beforeDefaultsFlat = {};
  const beforeSiteFlat = {};
  const afterDefaultsFlat = {};
  for (const key in pendingUpdateSite.before) {
    flattenObject(beforeDefaultsFlat, key, pendingUpdateSite.before[key]);
    flattenObject(beforeSiteFlat, key, site[key]);
    flattenObject(afterDefaultsFlat, key, pendingUpdateSite.after[key]);
  }
  const parseToNumberIfPossible = (value: string | number) => {
    const number = parseFloat(value as string);
    return isNaN(number) ? value : number;
  };
  return (
    <Table striped bordered style={{ fontSize: "14px" }}>
      <thead>
        <tr>
          <th>{pendingUpdateSite.title}</th>
          <th>Old default value</th>
          <th>Current value</th>
          <th>New value</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(beforeDefaultsFlat)
          .filter(
            (key) =>
              parseToNumberIfPossible(beforeSiteFlat[key]) !==
              parseToNumberIfPossible(afterDefaultsFlat[key])
          )
          .sort(sortBy((key) => formatFieldName(lossFactorsByKey, key)))
          .map((key) => (
            <tr key={key}>
              <td>{formatFieldName(lossFactorsByKey, key)}</td>
              <td>{beforeDefaultsFlat[key]}</td>
              <td>{beforeSiteFlat[key] || "0"}</td>
              <td>{afterDefaultsFlat[key]}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default ConfirmChangesModal;
