import React from "react";
import styled from "styled-components";
import BlueMarkerIcon from "../../images/icons/networkSiteBlue.svg";
import PurpleMarkerIcon from "../../images/icons/networkSitePurple.svg";
import YellowMarkerIcon from "../../images/icons/networkSiteYellow.svg";

const mySitePurple = "#a2a6ff";
const networkSiteBlue = "#2779a7";
const offerDestinationYellow = "#DBA507";

interface PulseMarkerProps {
  count?: number;
  type: "purple" | "blue" | "yellow" | "procure";
  testId?: string;
}

const PulseMarker: React.FC<PulseMarkerProps> = ({ count, type, testId }) => {
  const center = getCenter(count, type);
  return (
    <Wrapper data-testid={testId}>
      {center}
      {["0s", "1s", "1.5s", "2s", "2.5s"].map((animationDelay) => (
        <PulsingCircles
          key={animationDelay}
          style={{ animationDelay }}
          type={type}
        />
      ))}
    </Wrapper>
  );
};

export default PulseMarker;

const getCenter = (
  count: number | undefined,
  type: "purple" | "blue" | "yellow" | "procure"
) => {
  let color: string;
  let icon: string;
  if (type === "purple" || type === "procure") {
    color = mySitePurple;
    icon = PurpleMarkerIcon;
  } else if (type === "blue") {
    color = networkSiteBlue;
    icon = BlueMarkerIcon;
  } else if (type === "yellow") {
    color = offerDestinationYellow;
    icon = YellowMarkerIcon;
  } else {
    throw new Error("Invalid type");
  }
  if (count === undefined || count < 1) {
    return <img src={icon} alt="building marker" height={24} />;
  } else {
    return <MultiCount color={color}>{count}</MultiCount>;
  }
};

interface PulsingCirclesProps {
  style: React.CSSProperties;
  type: "purple" | "blue" | "yellow" | "procure";
}

const PulsingCircles: React.FC<PulsingCirclesProps> = ({ style, type }) => {
  return <Circle className={`pulsing-circle-${type}`} style={style} />;
};

interface MultiCountProps {
  color: string;
}

const MultiCount = styled.div<MultiCountProps>`
  position: absolute;
  ${({ color }) => `background: ${color};`}
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 25px;
  opacity: 0.8;
`;

const Wrapper = styled.div`
  width: 90px;
  height: 90px;
  margin-left: -33px;
  margin-top: -33px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  pointer-events: none;
`;

const Circle = styled.div`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  position: absolute;
  opacity: 0;
`;
