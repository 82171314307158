import { useOrganisationList } from "@inrange/building-manager-api-client";
import { Modal, ModalView } from "@inrange/theme-components";
import { useSearchParams } from "react-router-dom";

import {
  DEFAULT_DEMAND_COEFFICIENTS,
  DEFAULT_DEMAND_PROFILES,
  useSite,
} from "@inrange/building-manager-api-client";
import { Organisation } from "@inrange/building-manager-api-client/models-organisation";
import {
  PartialSiteAllowStringValues,
  SiteAllowStringValues,
  SiteOwnership,
} from "@inrange/building-manager-api-client/models-site";
import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import CreateOrg from "../../../org/CreateOrg";
import { SITE_ORG_LABELS } from "../enums";

type AddOwnershipProps = {
  site: SiteAllowStringValues;
  setSite: (site: PartialSiteAllowStringValues) => void;
  ownerships: SiteOwnership[];
  onOwnerAdded: (ownership: {
    ownershipIndex: number;
    selectedID: string;
    selectedName: string;
    selectedType: string;
    selectedPark: string;
  }) => void;
  setNewSiteName: (name: string, ownerships: SiteOwnership[]) => void;
  error: string;
};

const AddOwnership = ({
  site,
  setSite,
  ownerships,
  onOwnerAdded,
  setNewSiteName,
  error,
}: AddOwnershipProps) => {
  const { fetchOrganisations } = useOrganisationList();

  const refetchOrganisations = async () => {
    await fetchOrganisations.refetch();
  };

  const organisations = (fetchOrganisations.data?.organisations || []).sort(
    (a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    }
  );

  const [ownershipCount, setOwnershipCount] = useState(1);

  const ownershipSelectors = [...Array(ownershipCount)].map((_, i) => {
    return (
      <OwnershipSelection
        site={site}
        setSite={setSite}
        key={i}
        ownershipIndex={i}
        error={error}
        organisations={organisations}
        ownerships={ownerships}
        onOwnerAdded={onOwnerAdded}
        setNewSiteName={setNewSiteName}
        refetchOrganisations={refetchOrganisations}
      />
    );
  });

  const incrementSelectors = () => {
    setOwnershipCount(ownershipCount + 1);
  };

  return (
    <Row style={{ display: "flex", gap: 10, marginTop: 5, padding: 10 }}>
      <Col>
        <Row>
          <Form.Label>Add site to an organisation</Form.Label>
        </Row>
        {ownershipSelectors}
        <Row>
          <Form.Group className="mb-3">
            <Button variant="success" onClick={incrementSelectors}>
              Another ownership
            </Button>
          </Form.Group>
          <Form.Label style={{ color: "#DC3543" }}>{error}</Form.Label>
        </Row>
      </Col>
    </Row>
  );
};

export default AddOwnership;

type OwnershipSelectionProps = {
  site: SiteAllowStringValues;
  setSite: (site: PartialSiteAllowStringValues) => void;
  ownershipIndex: number;
  error: string;
  organisations: Organisation[];
  ownerships: SiteOwnership[];
  onOwnerAdded: (ownership: {
    ownershipIndex: number;
    selectedID: string;
    selectedName: string;
    selectedType: string;
    selectedPark: string;
  }) => void;
  setNewSiteName: (name: string, ownerships: SiteOwnership[]) => void;
  refetchOrganisations: () => void;
};

const OwnershipSelection = ({
  site,
  setSite,
  ownershipIndex,
  error,
  organisations,
  ownerships,
  onOwnerAdded,
  setNewSiteName,
  refetchOrganisations,
}: OwnershipSelectionProps) => {
  const { fetchSiteValues } = useSite({
    app: "admin",
    enableNonEssentialQueries: true,
  });
  const [searchParams, _setSearchParams] = useSearchParams();
  const urlOrgId = searchParams.get("org");
  const [urlOrgIdConfigured, setUrlOrgIdConfigured] = useState(
    ownershipIndex > 0
  );
  const [orgId, setOrgId] = useState(
    ownershipIndex === 0 ? urlOrgId || "" : ""
  );
  const [ownershipType, setOwnershipType] = useState("");
  const [park, setPark] = useState("");
  const [showNewOrgModal, setShowNewOrgModal] = useState(false);
  const [showApplyOrgSettingsModal, setShowApplyOrgSettingsModal] =
    useState(false);

  const ownershipsArray = Object.values(ownerships);
  const notSelectableOwnerships = new Set(ownershipsArray.map((o) => o.orgID));
  notSelectableOwnerships.delete(orgId);

  const orgsById = organisations.reduce(
    (acc: Record<string, Organisation>, org: Organisation) => {
      acc[org.id] = org;
      return acc;
    },
    {}
  );

  const setSiteName = useCallback(
    (
      newSelectedID: string,
      newSelectedName: string,
      newSelectedType: string
    ) => {
      if (!newSelectedType) return;
      ownershipsArray[ownershipIndex] = {
        orgID: newSelectedID,
        name: newSelectedName,
        ownership: newSelectedType,
      };
      setNewSiteName(site.name, ownershipsArray);
    },
    [ownershipIndex, setNewSiteName, site.name, ownershipsArray]
  );

  const onOrgSelected = useCallback(
    (organisation: any, promptForOrgChanges: boolean) => {
      if (promptForOrgChanges && organisation.orgType === "Tenant") {
        if (
          (organisation.buildingProfile &&
            organisation.buildingProfile !== site.buildingProfile) ||
          (organisation.maxTenantTariff &&
            organisation.maxTenantTariff !== site.tenantTariff) ||
          (organisation.maxNetworkImportTariff &&
            organisation.maxNetworkImportTariff !== site.networkImportTariff)
        ) {
          setShowApplyOrgSettingsModal(true);
        }
      }

      setOrgId(organisation.id);
      setOwnershipType(
        organisation.orgType === "Tenant" ? "tenant" : "landlord"
      );

      onOwnerAdded({
        ownershipIndex,
        selectedID: organisation.id,
        selectedName: organisation.name,
        selectedType: organisation.orgType === "Tenant" ? "tenant" : "landlord",
        selectedPark: "",
      });
      setSiteName(
        organisation.id,
        organisation.name,
        organisation.orgType === "Tenant" ? "tenant" : "landlord"
      );
    },
    [onOwnerAdded, ownershipIndex, setSiteName, site]
  );

  const applyOrgSettings = useCallback(
    (selectedOrg: any) => {
      setSite({
        ...(selectedOrg.buildingProfile && {
          buildingProfile: selectedOrg.buildingProfile,
          demandCoefficientKWhPerM2:
            DEFAULT_DEMAND_COEFFICIENTS[selectedOrg.buildingProfile],
          profileShape: DEFAULT_DEMAND_PROFILES[selectedOrg.buildingProfile],
        }),
        ...(selectedOrg.orgType === "Tenant" &&
          selectedOrg.maxTenantTariff && {
            tenantTariff: selectedOrg.maxTenantTariff,
          }),
        ...(selectedOrg.orgType === "Tenant" &&
          selectedOrg.maxNetworkImportTariff && {
            networkImportTariff: selectedOrg.maxNetworkImportTariff,
          }),
        ...(selectedOrg.orgType === "Tenant" &&
          selectedOrg.defaultMarketTariff && {
            marketTariff: selectedOrg.defaultMarketTariff,
          }),
      });
    },
    [setSite]
  );

  useEffect(() => {
    if (
      urlOrgId &&
      organisations &&
      organisations.length > 0 &&
      !urlOrgIdConfigured
    ) {
      const selectedOrg = orgsById[urlOrgId];
      if (selectedOrg) {
        onOrgSelected(selectedOrg, false);
        applyOrgSettings(selectedOrg);
      }
      setUrlOrgIdConfigured(true);
    }
  }, [
    applyOrgSettings,
    onOrgSelected,
    urlOrgId,
    organisations,
    orgsById,
    urlOrgIdConfigured,
    setSite,
  ]);

  const selectedOrg = orgsById[orgId];

  return (
    <Row>
      <Col>
        <Form.Group className="mb-3">
          <Form.Select
            value={orgId ?? ""}
            onChange={(e) => {
              const newSelectedID = e.target.value;
              if (newSelectedID === "new") {
                setShowNewOrgModal(true);
                return;
              }
              onOrgSelected(orgsById[newSelectedID], true);
            }}
            onFocus={refetchOrganisations}
            data-testid={`orgSelect-${ownershipIndex}`}
          >
            <option disabled value="">
              Select Organisation
            </option>
            <option value="new">Create new</option>
            {(organisations || [])
              .filter((org) => !notSelectableOwnerships.has(org.id))
              .map((o) => (
                <option key={o.id} value={o.id}>
                  {o.name}
                </option>
              ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group className="mb-3">
          <Form.Select
            value={ownershipType ?? ""}
            onChange={(e) => {
              const newSelectedType = e.target.value;
              setOwnershipType(newSelectedType);
              onOwnerAdded({
                ownershipIndex,
                selectedID: orgId,
                selectedName: selectedOrg?.name || "",
                selectedType: newSelectedType,
                selectedPark: park,
              });
              setSiteName(orgId, selectedOrg?.name, newSelectedType);
            }}
            isInvalid={
              !!error && ["landlord", "ownerOccupier"].includes(ownershipType)
            }
            data-testid={`orgType-${ownershipIndex}`}
          >
            <option disabled value="">
              Select Status
            </option>
            {Object.keys(SITE_ORG_LABELS).map((key) => (
              <option key={key} value={key}>
                {SITE_ORG_LABELS[key]}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
      {selectedOrg && selectedOrg.parks.length > 0 && (
        <Col>
          <Form.Group className="mb-3" controlId="parkSelect">
            <Form.Select
              value={park ?? ""}
              onChange={(e) => {
                setPark(e.target.value);
                onOwnerAdded({
                  ownershipIndex,
                  selectedID: orgId,
                  selectedName: selectedOrg.name,
                  selectedType: ownershipType,
                  selectedPark: e.target.value,
                });
              }}
            >
              <option value="">No park</option>
              {selectedOrg.parks.sort().map((park) => (
                <option key={park} value={park}>
                  {park}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      )}
      {showNewOrgModal ? (
        <Modal>
          <ModalView
            title="Create new organisation"
            fontWeight="500"
            fontSize="20px"
            width="1000px"
          >
            <CreateOrg
              modal={true}
              handleModalCancel={() => setShowNewOrgModal(false)}
              handleModalSubmit={async (organisation: any) => {
                await refetchOrganisations();
                onOrgSelected(organisation, true);
                setShowNewOrgModal(false);
              }}
            />
          </ModalView>
        </Modal>
      ) : null}
      {showApplyOrgSettingsModal && (
        <Modal>
          <ModalView
            title={`Apply org settings?`}
            titlePadding="0 0 10px 0"
            fontWeight="500"
            fontSize="20px"
            centerTitle={true}
            width="600px"
          >
            <div>
              <ul>
                {selectedOrg.buildingProfile &&
                  selectedOrg.buildingProfile !== site.buildingProfile && (
                    <li>
                      Building profile:{" "}
                      {
                        fetchSiteValues.data!.options.buildingProfiles[
                          site.buildingProfile
                        ].name
                      }{" "}
                      →{" "}
                      {
                        fetchSiteValues.data!.options.buildingProfiles[
                          selectedOrg.buildingProfile
                        ].name
                      }
                    </li>
                  )}
                {selectedOrg.maxTenantTariff &&
                  selectedOrg.maxTenantTariff !== site.tenantTariff && (
                    <li>
                      Tenant tariff: {site.tenantTariff} →{" "}
                      {selectedOrg.maxTenantTariff}
                    </li>
                  )}
                {selectedOrg.maxNetworkImportTariff &&
                  selectedOrg.maxNetworkImportTariff !==
                    site.networkImportTariff && (
                    <li>
                      Network import tariff: {site.networkImportTariff} →{" "}
                      {selectedOrg.maxNetworkImportTariff}
                    </li>
                  )}
              </ul>
            </div>
            <Row style={{ marginTop: "10px", justifyContent: "center" }}>
              <Col md={4}>
                <Button
                  variant="success"
                  onClick={() => {
                    applyOrgSettings(orgsById[orgId]);
                    setShowApplyOrgSettingsModal(false);
                  }}
                  style={{ float: "right" }}
                >
                  Yes, update
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  variant="secondary"
                  onClick={() => setShowApplyOrgSettingsModal(false)}
                >
                  No, don&apos;t update
                </Button>
              </Col>
            </Row>
          </ModalView>
        </Modal>
      )}
    </Row>
  );
};
