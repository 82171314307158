import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useQuery } from "react-query";
import getSiteSdmList from "../http/getSiteSdmList";
import { SdmOffer, SdmSiteOffer } from "../models/site";

interface UseSiteSdmProps {
  siteId?: string;
  getAllResults?: boolean;
  onlyLinkedOrgs?: boolean;
  isWired?: boolean;
  userOrgId?: string;
}

const useSiteSdm = ({
  siteId,
  getAllResults,
  onlyLinkedOrgs = false,
  isWired = false,
  userOrgId,
}: UseSiteSdmProps) => {
  const { getToken } = useKindeAuth();

  // Note: userOrgId is a required parameter for the customer app
  // usage of this API. It is not required for the admin app.

  const fetchSiteSdmList = useQuery<
    | {
        result: {
          buy?: SdmOffer;
          sell?: SdmOffer;
        };
      }
    | { results: SdmSiteOffer[] }
  >(
    ["site", siteId, "sdmList", isWired],
    async () => {
      return getSiteSdmList(
        await getToken(),
        siteId,
        getAllResults,
        onlyLinkedOrgs,
        userOrgId,
        isWired
      );
    },
    { enabled: !!siteId }
  );

  let bestBuyOffer: SdmOffer | undefined = undefined;
  let bestSellOffer: SdmOffer | undefined = undefined;
  if (fetchSiteSdmList.data) {
    if ("result" in fetchSiteSdmList.data) {
      bestBuyOffer = fetchSiteSdmList.data?.result?.buy;
      bestSellOffer = fetchSiteSdmList.data?.result?.sell;
    }
  }

  return {
    fetchSiteSdmList,
    bestBuyOffer,
    bestSellOffer,
  };
};

export default useSiteSdm;
