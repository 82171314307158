import React from "react";
import Select, { ActionMeta, MultiValue } from "react-select";
import styled from "styled-components";
import magnifyingGlass from "../images/icons/magnifyingGlass.svg";
import IconInput from "./input/IconInput";

export interface SelectFilterOption {
  label: string;
  value: string[] | string;
  id: string;
}

interface TableListSortFiltersProps {
  textFilter: string;
  setTextFilter: (value: string) => void;
  paddingRight?: string;
  parkFiltering?: {
    setSelectedParks: (value: string[]) => void;
    selectedParks: string[];
    parkSelectOptions: SelectFilterOption[];
    onParkFiltering: (action: string, filterOrgNames: string[]) => void;
  };
  orgFiltering?: {
    setSelectedOrgIDs: (value: string[]) => void;
    selectedOrgIDs: string[];
    ownershipSelectOptions: SelectFilterOption[];
    onOwnershipFiltering: (action: string, filterOrgNames: string[]) => void;
    isLandlord: boolean;
  };
}

const TableListSortFilters: React.FC<TableListSortFiltersProps> = ({
  textFilter,
  setTextFilter,
  paddingRight,
  parkFiltering,
  orgFiltering,
}) => {
  return (
    <TableListHeaderRight $paddingRight={paddingRight}>
      {parkFiltering && (
        <Select
          placeholder={`Filter by park...`}
          onChange={(
            values: MultiValue<SelectFilterOption>,
            actionMeta: ActionMeta<SelectFilterOption>
          ) => {
            const filterAction = actionMeta.action;
            const filterOrgNames: string[] = [];
            if (filterAction === "clear") {
              actionMeta.removedValues?.forEach((value) => {
                filterOrgNames.push(value.label);
              });
            } else if (filterAction === "remove-value") {
              filterOrgNames.push(actionMeta.removedValue?.label || "");
            } else if (filterAction === "select-option") {
              filterOrgNames.push(actionMeta.option?.label || "");
            }
            parkFiltering.onParkFiltering(filterAction, filterOrgNames);
            parkFiltering.setSelectedParks(values.map((value) => value.id));
          }}
          onFocus={() => {
            parkFiltering.onParkFiltering("focused", []);
          }}
          options={parkFiltering.parkSelectOptions.filter(
            (option) => !parkFiltering.selectedParks.includes(option.id)
          )}
          defaultValue={parkFiltering.parkSelectOptions.filter((option) =>
            parkFiltering.selectedParks.includes(option.id)
          )}
          maxMenuHeight={320}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              border: "1px solid #E5E7EB",
              borderRadius: "8px",
              boxSizing: "border-box",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
              minHeight: "44px",
              minWidth: "214px",
              maxWidth: "500px",
              fontSize: "14px",
            }),
          }}
          isMulti
          key="parkFilterSelect"
          aria-label="Filter by park"
        />
      )}
      {orgFiltering && (
        <Select
          placeholder={`Filter by ${orgFiltering.isLandlord ? "tenant" : "landlord"}...`}
          onChange={(
            values: MultiValue<SelectFilterOption>,
            actionMeta: ActionMeta<SelectFilterOption>
          ) => {
            const filterAction = actionMeta.action;
            const filterOrgNames: string[] = [];
            if (filterAction === "clear") {
              actionMeta.removedValues?.forEach((value) => {
                filterOrgNames.push(value.label);
              });
            } else if (filterAction === "remove-value") {
              filterOrgNames.push(actionMeta.removedValue?.label || "");
            } else if (filterAction === "select-option") {
              filterOrgNames.push(actionMeta.option?.label || "");
            }
            orgFiltering.onOwnershipFiltering(filterAction, filterOrgNames);
            orgFiltering.setSelectedOrgIDs(values.map((value) => value.id));
          }}
          onFocus={() => {
            orgFiltering.onOwnershipFiltering("focused", []);
          }}
          options={orgFiltering.ownershipSelectOptions.filter(
            (option) => !orgFiltering.selectedOrgIDs.includes(option.id)
          )}
          defaultValue={orgFiltering.ownershipSelectOptions.filter((option) =>
            orgFiltering.selectedOrgIDs.includes(option.id)
          )}
          maxMenuHeight={320}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              border: "1px solid #E5E7EB",
              borderRadius: "8px",
              boxSizing: "border-box",
              boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
              minHeight: "44px",
              minWidth: "214px",
              maxWidth: "500px",
              fontSize: "14px",
            }),
          }}
          isMulti
          key="orgFilterSelect"
          aria-label="Filter by shared ownership"
        />
      )}
      <IconInput
        icon={magnifyingGlass}
        type="search"
        placeholder="Search"
        value={textFilter}
        onChange={(e) => setTextFilter(e.target.value)}
        width="214px"
        testId="table-list-text-filter"
      />
    </TableListHeaderRight>
  );
};

export default TableListSortFilters;

const TableListHeaderRight = styled.div<{ $paddingRight?: string }>`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  flex-grow: 1;
  padding-right: ${({ $paddingRight }) => $paddingRight || "12px"};
`;
