import { useSite } from "@inrange/building-manager-api-client";
import { getPVAUrl } from "@inrange/inrange-data-api-client";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import {
  OFFTAKER_ONLY_STATUS_OPTIONS,
  STATUS_OPTIONS,
} from "../../operationalStatusUtils";
import { SITE_STATUS_LABELS } from "../enums";
import { epochSecondsToDate } from "../utils";
import AddOwnership from "./AddOwnership";
import BelongsTo from "./BelongsTo";

const SiteInformation = ({
  site,
  offtakerOnlySite,
  setSite,
  ownerships,
  onOwnerAdded,
  onOwnerRemoved,
  setEditedOwnerships,
  setNewSiteName,
  errors,
  allowCopy,
}) => {
  const [copyDisabled, setCopyDisabled] = useState(false);
  const parseCoordinates = (lat, lng) => {
    const parsedLat = parseFloat(lat);
    const parsedLong = parseFloat(lng);
    return [parsedLat, parsedLong];
  };

  const operationalStatusOptions = offtakerOnlySite
    ? OFFTAKER_ONLY_STATUS_OPTIONS
    : STATUS_OPTIONS;

  const testMode = !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE;

  const { copySite } = useSite({ app: "admin" });
  const doCopySite = async () => {
    setCopyDisabled(true);
    copySite.mutate({ siteId: site.id, copyBody: { pvaUrl: getPVAUrl() } });
  };

  if (copySite.isError) {
    setCopyDisabled(false);
    alert(`Error copy site - ${copySite.error.message}`);
  }

  if (copySite.isSuccess) {
    setCopyDisabled(false);
    const response = copySite.data;
    window.open(`/site/${response.site_id}/edit`, "_blank");
    copySite.reset();
  }

  return (
    <Card body>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Form.Label>
          <strong>Site information</strong>
        </Form.Label>
        {allowCopy && (
          <Button
            variant="inrangesecondary"
            style={{ marginLeft: "auto" }}
            onClick={doCopySite}
            disabled={copyDisabled}
          >
            Duplicate site
          </Button>
        )}
      </div>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="siteName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={site.name}
              onChange={(e) => setSite({ name: e.target.value })}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Property record ID</Form.Label>
            <Form.Control
              disabled
              value={testMode ? "fixed-id-for-testing" : site.id}
            />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group className="mb-3">
            <Form.Label>LatLng</Form.Label>
            <Form.Control
              disabled
              value={parseCoordinates(site.latitude, site.longitude)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Lease/License</Form.Label>
            <Form.Select
              value={site.investmentModel || ""}
              onChange={(e) => setSite({ investmentModel: e.target.value })}
            >
              {Object.keys(SITE_STATUS_LABELS).map((key) => (
                <option key={key} value={key}>
                  {SITE_STATUS_LABELS[key]}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row sm={2}>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Operational status</Form.Label>
            <Form.Select
              value={site.operationalStatus}
              onChange={(e) =>
                setSite({
                  operationalStatus: e.target.value,
                })
              }
              isInvalid={errors.operationalStatus}
            >
              {Object.entries(operationalStatusOptions).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid" style={{ width: "200%" }}>
              {errors.operationalStatus}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className={"mb-3"}>
            <Form.Label>
              {site.operationalStatus === "operational"
                ? "Commercially operational date"
                : "Est. commercially operational date"}
            </Form.Label>
            <Form.Control
              style={{
                color: site.commerciallyOperationalDateEpochSeconds
                  ? "black"
                  : "grey",
              }}
              type="date"
              onClick={(e) => {
                const element = e.target;
                if ("showPicker" in HTMLInputElement.prototype) {
                  // showPicker() is supported. Chrome 99+ and Safari 16+
                  // see https://caniuse.com/?search=showPicker
                  element.showPicker();
                }
              }}
              value={epochSecondsToDate(
                site.commerciallyOperationalDateEpochSeconds
              )}
              onChange={(e) => {
                const date = new Date(e.target.value);
                const invalidDate = isNaN(date);
                const epochSeconds = invalidDate ? null : date.getTime() / 1000; // if the date was cleared, set to null, otherwise getTime returns milliseconds, but we want seconds
                setSite({
                  commerciallyOperationalDateEpochSeconds: epochSeconds,
                });
              }}
              isInvalid={errors.operationalStatus}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Check // prettier-ignore
              type={"checkbox"}
              label={"Exclude this site from customer-facing SDM"}
              checked={site.isScenario}
              onChange={(e) => setSite({ isScenario: e.target.checked })}
            />
          </Form.Group>
        </Col>
      </Row>
      {site.siteMPANs.map((mpan, index) => (
        <div
          key={index}
          style={{ display: "flex", flexDirection: "row", gap: "24px" }}
        >
          <div style={{ flex: 1 }}>
            <Form.Group className="mb-3">
              <Form.Label>Import MPAN</Form.Label>
              <Form.Control
                type="text"
                value={mpan.importMPAN ?? ""}
                onChange={(e) => {
                  const newMPANs = [...site.siteMPANs];
                  newMPANs[index].importMPAN = e.target.value;
                  setSite({ siteMPANs: newMPANs });
                }}
                isInvalid={errors[`siteMPANs.${index}.importMPAN`]}
              />
              <Form.Control.Feedback type="invalid">
                {errors[`siteMPANs.${index}.importMPAN`]}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div style={{ flex: 1 }}>
            <Form.Group className="mb-3">
              <Form.Label>Export MPAN</Form.Label>
              <Form.Control
                type="text"
                value={mpan.exportMPAN ?? ""}
                onChange={(e) => {
                  const newMPANs = [...site.siteMPANs];
                  newMPANs[index].exportMPAN = e.target.value;
                  setSite({ siteMPANs: newMPANs });
                }}
                isInvalid={errors[`siteMPANs.${index}.exportMPAN`]}
              />
              <Form.Control.Feedback type="invalid">
                {errors[`siteMPANs.${index}.exportMPAN`]}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div style={{ width: "35px" }}>
            {index === site.siteMPANs.length - 1 && (
              <Form.Group className="mb-3">
                <Form.Label>&nbsp;</Form.Label>
                <Button
                  variant="success"
                  onClick={() => {
                    const newMPANs = [...site.siteMPANs];
                    newMPANs.push({ importMPAN: null, exportMPAN: null });
                    setSite({ siteMPANs: newMPANs });
                  }}
                  style={{ display: "block" }}
                >
                  +
                </Button>
              </Form.Group>
            )}
          </div>
        </div>
      ))}
      <Card>
        {ownerships?.length > 0 && (
          <>
            <BelongsTo
              siteID={site.id}
              ownerships={ownerships}
              onOwnerRemoved={onOwnerRemoved}
              setEditedOwnerships={setEditedOwnerships}
            />
            <hr style={{ margin: "4px 0" }} />
          </>
        )}
        <AddOwnership
          site={site}
          setSite={setSite}
          onOwnerAdded={onOwnerAdded}
          ownerships={ownerships}
          setNewSiteName={setNewSiteName}
          error={errors.siteOwnerships ?? ""}
        />
      </Card>
    </Card>
  );
};

export default SiteInformation;
