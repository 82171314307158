import { useOrganisation } from "@inrange/building-manager-api-client";
import { Icons } from "@inrange/theme-components/icons";
import { useNavigate } from "react-router-dom";
import OrgSettingsForm from "./OrgSettingsForm";

interface CreateOrgProps {
  modal?: boolean;
  handleModalSubmit?: (organisation: any) => void;
  handleModalCancel?: () => void;
}

const CreateOrg: React.FC<CreateOrgProps> = ({
  modal = false,
  handleModalSubmit = () => {},
  handleModalCancel = () => {},
}) => {
  const navigate = useNavigate();

  const { createOrg } = useOrganisation();

  const handleFormSubmit = (payload: any) => {
    createOrg.mutate({ organisation: payload });
  };

  const handleFormCancel = () => {
    handleModalCancel();
  };

  const disableSubmit = createOrg.isLoading || createOrg.isSuccess;

  const params = new URLSearchParams(window.location.search);
  const testCreateTemporaryOrg =
    params.get("testCreateTemporaryOrg") === "true";

  if (createOrg.isError)
    alert(`Error creating organisation - ${(createOrg.error as any).message}`);

  if (createOrg.isSuccess) {
    const { organisation } = createOrg.data as any;
    if (modal) {
      handleModalSubmit(organisation);
    } else {
      navigate(
        `/organisation/${organisation.id}/edit${
          testCreateTemporaryOrg ? "?testCreateTemporaryOrg=true" : ""
        }`
      );
    }
  }

  return (
    <>
      {!modal && <h5>Create new organisation</h5>}
      <OrgSettingsForm
        organisation={undefined}
        handleSubmit={handleFormSubmit}
        isDisableSubmit={disableSubmit}
      />
      {modal && (
        <img
          style={{
            position: "absolute",
            top: 4,
            right: 4,
            cursor: "pointer",
          }}
          src={Icons.close}
          onClick={handleFormCancel}
        />
      )}
    </>
  );
};

export default CreateOrg;
