import { useOrganisationList } from "@inrange/building-manager-api-client";
import { Organisation } from "@inrange/building-manager-api-client/models-organisation";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Loading from "../Loading";
import CreateOrg from "./CreateOrg";
import PVSummary from "./PVSummary";
import SiteMap from "./SiteMap";

const OrgList: React.FC = () => {
  document.title = `Organisation List`;
  const { fetchOrganisations } = useOrganisationList();
  const [searchValue, setSearchValue] = useState<string>("");
  const [displayOrgs, setDisplayOrgs] = useState<Organisation[]>([]);
  const [sortColumn, setSortColumn] = useState<string>("name");

  useEffect(() => {
    if (!fetchOrganisations.data?.organisations) return;

    let sortedOrgs = fetchOrganisations.data?.organisations;
    if (sortColumn === "name") {
      sortedOrgs.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    }

    if (sortColumn === "siteCount") {
      sortedOrgs = sortedOrgs.sort((a, b) => {
        if (countSites(a) < countSites(b)) return 1;
        if (countSites(a) > countSites(b)) return -1;
        return 0;
      });
    }

    if (searchValue.length > 1) {
      sortedOrgs = sortedOrgs.filter((org) =>
        org.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    setDisplayOrgs([...sortedOrgs]);
  }, [fetchOrganisations.data?.organisations, searchValue, sortColumn]);

  if (fetchOrganisations.isLoading)
    return <Loading label="Loading organisations..." />;

  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <CreateOrg />
        </Col>
        <Col xs={5} md={3}>
          <h6>PV Summary</h6>
          <PVSummary />
        </Col>
      </Row>
      <OrgListView
        organisations={displayOrgs}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setSortColumn={setSortColumn}
      />
      <SiteMap />
    </Container>
  );
};

const countSites = (org: Organisation): number => {
  return org.siteOwnerships?.length || 0;
};

const OrgListView: React.FC<{
  organisations: Organisation[];
  searchValue: string;
  setSearchValue: (value: string) => void;
  setSortColumn: (column: string) => void;
}> = ({ organisations, searchValue, setSearchValue, setSortColumn }) => {
  return (
    <>
      <Row style={{ marginBottom: 32, marginTop: 32 }}>
        <Col>
          <h3>Organisations ({organisations.length})</h3>
        </Col>
        <Col>
          <OrgSearchBox
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
        </Col>
      </Row>

      <Table striped bordered>
        <thead>
          <tr>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => setSortColumn("name")}
            >
              Name
            </th>
            <th>Users</th>
            <th
              style={{ cursor: "pointer" }}
              onClick={() => setSortColumn("siteCount")}
              align="right"
            >
              Number of sites
            </th>
            <th align="right">Unique ID</th>
          </tr>
        </thead>
        <tbody>
          {organisations.map((org) => (
            <OrgRow key={org.id} org={org} />
          ))}
        </tbody>
      </Table>
    </>
  );
};

const OrgRow: React.FC<{ org: Organisation }> = ({ org }) => {
  const siteCount = countSites(org);
  const orgIsTestString = org.isTest ? "Test " : "";
  return (
    <tr key={org.id}>
      <td>
        <div>
          <a
            href={`/organisation/${org.id}/edit`}
            data-testid={`org-id-link-${org.id}`}
          >
            <strong>{org.name}</strong>
          </a>
        </div>
        <div style={{ fontSize: 10, color: "gray" }}>
          {orgIsTestString + org.orgType} | {org.id}
        </div>
      </td>
      <td style={{ fontSize: 12 }}>
        {org.userEmails?.length > 0 && (
          <>
            Users:
            <ul>
              {org.userEmails?.map((userEmail) => (
                <li key={userEmail}>{userEmail}</li>
              ))}
            </ul>
          </>
        )}
      </td>
      <td align="center">{siteCount}</td>
      <td align="left">{org.id}</td>
    </tr>
  );
};

const OrgSearchBox: React.FC<{
  searchValue: string;
  setSearchValue: (value: string) => void;
}> = ({ searchValue, setSearchValue }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setSearchValue(inputValue);
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <Form.Control
        type="search"
        placeholder="Search"
        value={searchValue}
        onChange={handleInputChange}
      />
    </Form>
  );
};

export default OrgList;
