import {
  AllowStringForNumbers,
  Site,
  SiteAllowStringValues,
} from "@inrange/building-manager-api-client/models-site";
import {
  SiteCalculationsProvider,
  SitePreview,
} from "@inrange/shared-components";
import { Modal, ModalView } from "@inrange/theme-components";
import { useState } from "react";
import { Button } from "react-bootstrap";
import DealTerms from "../site/siteSections/DealTerms";

const BulkEditDealTerms = ({
  selectedSites,
  onSitesUpdate,
  onClose,
}: {
  selectedSites: Set<string>;
  onSitesUpdate: (payload: any) => void;
  onClose: () => void;
}) => {
  const [site, setSite] = useState<
    Partial<
      AllowStringForNumbers<{
        id: string;
        ppaLength: number;
        exportPPALength: number;
        ppaType: string;
        exportPPAType: string;
        ppaIndex: number;
        exportPPAIndex: number;
        leaseLengthDateEpochSeconds?: number;
        systemLifetimeYears: number;
        vacancyLossRate: number;
      }>
    >
  >({
    id: "bulk-preview",
    ppaLength: undefined,
    ppaType: "fixed",
    ppaIndex: undefined,
    exportPPALength: undefined,
    exportPPAType: "fixed",
    exportPPAIndex: undefined,
    leaseLengthDateEpochSeconds: undefined,
    systemLifetimeYears: undefined,
    vacancyLossRate: undefined,
  });
  return (
    <Modal>
      <ModalView
        title={`Edit deal and contract terms for ${selectedSites.size} site${selectedSites.size === 1 ? "" : "s"}`}
        fontWeight="500"
        fontSize="20px"
        width="800px"
        titlePadding="0 0 10px 0"
      >
        <SiteCalculationsProvider
          site={site as unknown as Partial<Site>}
          setSaveDisabled={() => {}}
          app="admin"
          isBulkEdit={true}
        >
          <SitePreview
            previewSite={site as unknown as Partial<Site>}
            originalSite={{}}
          >
            <DealTerms
              site={site as unknown as SiteAllowStringValues}
              setSite={(update) => {
                setSite((prevSite) => ({ ...prevSite, ...update }));
              }}
              isOwnerOccupied={false}
            />
            <div
              style={{
                marginTop: "16px",
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Button variant="inrangesecondary" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  // Or all the values with undefined to remove blank strings
                  onSitesUpdate({
                    site: {
                      ppaLength: site.ppaLength || undefined,
                      ppaType: site.ppaType || undefined,
                      ppaIndex: site.ppaIndex || undefined,
                      exportPPALength: site.exportPPALength || undefined,
                      exportPPAType: site.exportPPAType || undefined,
                      exportPPAIndex: site.exportPPAIndex || undefined,
                      leaseLengthDateEpochSeconds:
                        site.leaseLengthDateEpochSeconds || undefined,
                      systemLifetimeYears:
                        site.systemLifetimeYears || undefined,
                      vacancyLossRate: site.vacancyLossRate || undefined,
                    },
                  });
                }}
              >
                Edit deal and contract terms
              </Button>
            </div>
          </SitePreview>
        </SiteCalculationsProvider>
      </ModalView>
    </Modal>
  );
};

export default BulkEditDealTerms;
