import { useSite, useSiteUpdate } from "@inrange/building-manager-api-client";
import { Organisation } from "@inrange/building-manager-api-client/models-organisation";
import {
  Site,
  SiteValuesOptions,
} from "@inrange/building-manager-api-client/models-site";
import { sortBy } from "@inrange/calculations/utils.ts";
import { formatDateTimeFromSeconds } from "@inrange/theme-components";
import {
  formatCurrency,
  formatPercentage,
  formatUnits,
  fractionalCurrencySymbol,
} from "@inrange/theme-components/formatting";
import { useState } from "react";
import {
  Alert,
  Button,
  Col,
  Dropdown,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import Select from "react-select";
import { csvHeaders, formatCsvRow } from "../../utils/SitesCsvUtil";
import Loading from "../Loading";
import { STATUS_OPTIONS } from "../site/operationalStatusUtils";
import { getCurtailmentDetails } from "../site/siteSections/dno-utils";
import { SITE_ORG_LABELS } from "../site/siteSections/enums";
import { formatNumber } from "../site/siteSections/utils";
import BulkEditCosts from "./BulkEditCosts";
import BulkEditDealTerms from "./BulkEditDealTerms";
import BulkEditTariffs from "./BulkEditTariffs";

const getSiteOwnership = (site: Site, organisation: Organisation) => {
  return site.siteOwnerships.find(
    (siteOwnership) => siteOwnership.orgID === organisation.id
  );
};

const getOwnership = (site: Site, organisation: Organisation) => {
  return getSiteOwnership(site, organisation)?.ownership;
};

const siteIRR = (site: Site, organisation: Organisation) => {
  const ownership = getOwnership(site, organisation);
  if (ownership === "tenant") {
    return "N/A";
  }
  const irr = site.financialModels[ownership!]["license"].irr;
  return irr ? formatPercentage(irr, 2) : "N/A";
};

const validIRR = (site: Site, organisation: Organisation) => {
  if (
    organisation.orgType === "Tenant" ||
    !organisation.minIRR ||
    site.installedCapacity === 0
  ) {
    return true;
  }
  const ownership = getOwnership(site, organisation);
  if (ownership !== "landlord" && ownership !== "ownerOccupier") {
    return true;
  }
  const irr = site.financialModels[ownership!]["license"].irr;
  return irr !== undefined && irr >= organisation.minIRR;
};

const validTenantTariff = (site: Site, organisation: Organisation) => {
  if (organisation.orgType === "Landlord" || !organisation.maxTenantTariff) {
    return true;
  }
  const ownership = getOwnership(site, organisation);
  if (ownership !== "tenant" && ownership !== "ownerOccupier") {
    return true;
  }
  return site.tenantTariff <= organisation.maxTenantTariff;
};

const validNetworkImportTariff = (site: Site, organisation: Organisation) => {
  if (
    organisation.orgType === "Landlord" ||
    !organisation.maxNetworkImportTariff
  ) {
    return true;
  }
  const ownership = getOwnership(site, organisation);
  if (ownership !== "tenant" && ownership !== "ownerOccupier") {
    return true;
  }
  return site.networkImportTariff <= organisation.maxNetworkImportTariff;
};

const OrgSitesTable = ({
  organisation,
  sites,
  setFilteredParks,
}: {
  organisation: Organisation;
  sites: Site[];
  setFilteredParks: (parks: string[]) => void;
}) => {
  const { fetchSiteValues } = useSite({
    app: "admin",
    enableNonEssentialQueries: true,
  });
  const [selectedSites, setSelectedSites] = useState<Set<string>>(new Set());
  const [bulkEditMode, setBulkEditMode] = useState<undefined | string>(
    undefined
  );

  const [alertStyle, setAlertStyle] = useState<string>("light");
  const [alertMessage, setAlertMessage] = useState<string | undefined>(
    undefined
  );

  const siteUpdate = useSiteUpdate();
  const onSitesUpdate = async (payload: any) => {
    const selectedSitesForAction = Array.from(selectedSites);
    setBulkEditMode(undefined);
    setAlertStyle("light");
    setAlertMessage(`Updating ${selectedSitesForAction.length} sites...`);

    const mutations = selectedSitesForAction.map((siteId) =>
      siteUpdate.mutateAsync({ siteId, payload })
    );

    try {
      const results = await Promise.allSettled(mutations);
      const successfulUpdates = results.filter(
        (result) => result.status === "fulfilled"
      ).length;
      const failedUpdates = results.filter(
        (result) => result.status === "rejected"
      ).length;

      if (failedUpdates === 0) {
        setAlertStyle("success");
        setAlertMessage(`Successfully updated ${successfulUpdates} sites`);
      } else {
        setAlertStyle("danger");
        setAlertMessage(
          `Failed to update ${failedUpdates} out of ${selectedSitesForAction.length} sites`
        );
      }
    } catch (error) {
      setAlertStyle("danger");
      setAlertMessage(`An unexpected error occurred while updating sites`);
    }
  };

  const csvData = sites.map((site) => {
    return formatCsvRow(site, organisation.id, "unknown");
  });
  const dateString = new Date().toISOString().split(".")[0];
  const csvFileName = organisation.name + " Site List " + dateString + ".csv";

  const testMode = !import.meta.env.PROD && import.meta.env.VITE_TEST_MODE;

  const params = new URLSearchParams(window.location.search);
  const testCreateTemporaryOrg =
    params.get("testCreateTemporaryOrg") === "true";

  if (fetchSiteValues.isError) {
    return <div>Error loading site values.</div>;
  }

  if (fetchSiteValues.isLoading) {
    return <Loading label="Loading site values data..." />;
  }

  return (
    <div style={{ margin: "0" }}>
      {alertMessage && (
        <Alert
          variant={alertStyle}
          onClose={() => setAlertMessage(undefined)}
          dismissible={alertStyle !== "light"}
        >
          <div>{alertMessage}</div>
        </Alert>
      )}
      <Row style={{ marginTop: "12px", marginBottom: "12px" }}>
        <Col
          style={{
            justifyContent: "flex-start",
            alignContent: "start",
            display: "flex",
            gap: 16,
          }}
        >
          <h4>
            Sites <span>({sites?.length})</span>
          </h4>
          <div>
            <CSVLink data={csvData} headers={csvHeaders} filename={csvFileName}>
              <Button variant="primary" data-testid={"download-site-data"}>
                Download sites data
              </Button>
            </CSVLink>
          </div>
        </Col>
        <Col
          style={{
            justifyContent: "flex-end",
            alignContent: "end",
            display: "flex",
            gap: 16,
          }}
        >
          {organisation.parks.length > 0 && (
            <Select
              placeholder="Filter by park..."
              onChange={(values) => {
                setFilteredParks(values.map((value) => value["value"]));
                setSelectedSites(new Set());
              }}
              options={organisation.parks.sort().map((park) => ({
                label: park,
                value: park,
              }))}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  border: "var(--bs-border-width) solid var(--bs-border-color)",
                  borderRadius: "var(--bs-border-radius)",
                  color: "var(--bs-body-color)",
                }),
              }}
              isMulti
              key="orgFilterSelect"
            />
          )}
          <Dropdown>
            <Dropdown.Toggle
              variant="primary"
              id="dropdown-basic"
              disabled={selectedSites.size === 0}
            >
              Act on selected
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                onClick={() => {
                  setBulkEditMode("edit-dealterms");
                }}
              >
                Edit deal & contract terms
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => {
                  setBulkEditMode("edit-costs");
                }}
              >
                Edit costs & rates
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => {
                  setBulkEditMode("edit-tariffs");
                }}
              >
                Edit tariffs
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <div>
            <a
              href={`/site/new?org=${organisation?.id}${
                testCreateTemporaryOrg
                  ? `&testCreateTemporaryOrg=true&testOrgId=${organisation?.id}`
                  : ""
              }`}
            >
              <Button variant="primary">New site</Button>
            </a>
          </div>
        </Col>
      </Row>
      <Table striped bordered>
        <thead>
          <tr>
            <th>
              <Form.Check
                type={"checkbox"}
                checked={selectedSites.size === sites.length}
                onChange={() => {
                  if (selectedSites.size !== sites.length) {
                    // Fewer than all selected, toggle to all selected
                    setSelectedSites(new Set(sites.map((site) => site.id)));
                  } else {
                    // All selected, toggle to none selected
                    setSelectedSites(new Set());
                  }
                }}
                data-testid={"orgsites-selectall"}
              />
            </th>
            <th>Site name</th>
            <th>Energy profile</th>
            <th>Status</th>
            <th>Address</th>
            <th>Modified</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {sites.map((site, ii) => (
            <OrgSiteRow
              siteValuesOptions={fetchSiteValues.data?.options}
              key={ii}
              index={ii}
              organisation={organisation}
              site={site}
              testMode={testMode}
              selectedSites={selectedSites}
              setSelectedSites={setSelectedSites}
            />
          ))}
        </tbody>
      </Table>
      {bulkEditMode === "edit-dealterms" && (
        <BulkEditDealTerms
          selectedSites={selectedSites}
          onSitesUpdate={onSitesUpdate}
          onClose={() => setBulkEditMode(undefined)}
        />
      )}
      {bulkEditMode === "edit-costs" && (
        <BulkEditCosts
          selectedSites={selectedSites}
          onSitesUpdate={onSitesUpdate}
          onClose={() => setBulkEditMode(undefined)}
        />
      )}
      {bulkEditMode === "edit-tariffs" && (
        <BulkEditTariffs
          selectedSites={selectedSites}
          onSitesUpdate={onSitesUpdate}
          onClose={() => setBulkEditMode(undefined)}
        />
      )}
    </div>
  );
};

const OrgSiteRow = ({
  siteValuesOptions,
  index,
  organisation,
  site,
  testMode,
  selectedSites,
  setSelectedSites,
}: {
  siteValuesOptions?: SiteValuesOptions;
  index: number;
  organisation: Organisation;
  site: Site;
  testMode: boolean;
  selectedSites: Set<string>;
  setSelectedSites: React.Dispatch<React.SetStateAction<Set<string>>>;
}) => {
  const ownership = getOwnership(site, organisation);
  const [curtailment, curtailmentRisk, curtailmentColor] =
    getCurtailmentDetails(site, site.energyFlowAnnual);

  const haveActualDemandData = site.halfHourlyDemand?.source === "hh_data";
  const demandCoefficient = haveActualDemandData
    ? site.totalBuildingArea > 0
      ? site.energyFlowAnnual.demand / site.totalBuildingArea
      : 0
    : site.demandCoefficientKWhPerM2;

  return (
    <tr>
      <td>
        <Form.Check
          type={"checkbox"}
          label={""}
          checked={selectedSites.has(site.id)}
          onChange={() => {
            if (selectedSites.has(site.id)) {
              selectedSites.delete(site.id);
              setSelectedSites(new Set(selectedSites));
            } else {
              selectedSites.add(site.id);
              setSelectedSites(new Set(selectedSites));
            }
          }}
          data-testid={`orgsites-select-${index}`}
        />
      </td>
      <td>
        <a href={`/site/${site.id}/edit`}>{site.name}</a>
        <br />
        {site.isScenario ? "(Excluded from customer-facing SDM)" : ""}
        <div style={{ fontSize: 10, color: "gray" }}>
          {testMode ? "fixed-id-for-testing" : site.id}
        </div>
        {getSiteOwnership(site, organisation)?.park && (
          <div style={{ fontSize: 13 }}>
            Park: {getSiteOwnership(site, organisation)?.park}
          </div>
        )}
      </td>
      <td width="20%" style={{ fontSize: 13 }}>
        <div>
          Installed capacity:{" "}
          <strong>
            {formatUnits(site.installedCapacity, "kWp", 3)} (
            <span
              style={{
                color:
                  site.halfHourlyGeneration?.roofUtilization >= 1
                    ? "red"
                    : "inherit",
              }}
            >
              {formatPercentage(site.halfHourlyGeneration?.roofUtilization, 2)}
            </span>
            )
          </strong>
        </div>
        <div>
          Tenant energy share:{" "}
          <strong>
            {formatPercentage(site.energyFlowAnnual.tenantEnergyShare, 2)}
          </strong>
        </div>
        <div>
          Export capacity:{" "}
          <strong>
            {formatUnits(site.energyFlowAnnual.exported, "kWh", 2)}
          </strong>
        </div>
        <div>
          Profile:{" "}
          <strong>
            {siteValuesOptions?.buildingProfiles?.[site.buildingProfile]
              ?.name || "not selected"}{" "}
            ({formatNumber(demandCoefficient, 2, 8)})
          </strong>
        </div>
        {site.installedCapacity > 0 && (
          <div>
            Export limit:{" "}
            <strong>
              {site.exportLimit !== null && site.exportLimit !== undefined
                ? site.exportLimit
                : "-"}
            </strong>
          </div>
        )}
        {curtailment !== undefined && (
          <div>
            Curtailment:{" "}
            <strong style={{ color: curtailmentColor }}>{curtailment}</strong>
          </div>
        )}
        {curtailment === undefined && site.installedCapacity > 0 && (
          <div>
            Curtailment risk:{" "}
            <strong style={{ color: curtailmentColor }}>
              {curtailmentRisk}
            </strong>
          </div>
        )}
        <div>
          {(organisation.orgType === "Landlord" ||
            ownership === "ownerOccupier") && (
            <>
              IRR:{" "}
              <strong
                style={{
                  color: validIRR(site, organisation) ? "inherit" : "red",
                }}
              >
                {siteIRR(site, organisation)}
              </strong>
              ,{" "}
            </>
          )}
          {site.installedCapacity === 0 ? (
            <>
              Network import tariff:{" "}
              <strong
                style={{
                  color: validNetworkImportTariff(site, organisation)
                    ? "inherit"
                    : "red",
                }}
              >
                {formatUnits(
                  site.networkImportTariff * 100,
                  fractionalCurrencySymbol(site.currencyCode),
                  2
                )}
              </strong>
            </>
          ) : (
            <>
              Tenant tariff:{" "}
              <strong
                style={{
                  color: validTenantTariff(site, organisation)
                    ? "inherit"
                    : "red",
                }}
              >
                {formatUnits(
                  site.tenantTariff * 100,
                  fractionalCurrencySymbol(site.currencyCode),
                  2
                )}
              </strong>
            </>
          )}
        </div>
        <div>
          Cost per kWp:{" "}
          <strong>
            {formatCurrency(
              site.projectCosts.initialCostPerKWp,
              site.currencyCode
            )}
          </strong>
        </div>
      </td>
      <td width="20%" className="secondary" style={{ fontSize: 14 }}>
        {site.siteOwnerships
          .sort(sortBy((siteOrg) => `${siteOrg.ownership}:${siteOrg.name}`))
          .map((siteOrg) => (
            <div key={siteOrg.orgID} style={{ fontSize: 13 }}>
              {SITE_ORG_LABELS[siteOrg.ownership]}:{" "}
              <strong>
                {testMode
                  ? siteOrg.name!.replace(/-.*/, " - fixed-id-for-testing")
                  : siteOrg.name}
              </strong>
            </div>
          ))}
        <div style={{ fontSize: 13 }}>
          Lease length: <strong>{site.leaseLengthMonths || "-"}</strong>
        </div>
        <div style={{ fontSize: 13 }}>
          Operational status:{" "}
          <strong>{STATUS_OPTIONS[site.operationalStatus]}</strong>
        </div>
        {site.commerciallyOperationalDateEpochSeconds && (
          <div style={{ fontSize: 13 }}>
            CO date:{" "}
            <strong>
              {
                new Date(site.commerciallyOperationalDateEpochSeconds * 1000)
                  .toISOString()
                  .split("T")[0]
              }
            </strong>
          </div>
        )}
        <div style={{ fontSize: 13 }}>
          PV selected:{" "}
          <strong>{site.pvDesignSystemLastUpdatedAt ? "True" : "False"}</strong>
        </div>
        <div style={{ fontSize: 13 }}>
          Hardware:{" "}
          <strong>
            {site.pvPanelName} / {site.pvInverterBrand}
          </strong>
        </div>
        <div style={{ fontSize: 13 }}>
          Battery:{" "}
          <strong>
            {site.batteryPower && site.batteryCapacity
              ? `${site.batteryPower} MW / ${site.batteryCapacity} MWh (${formatCurrency(
                  site.costInputsBattery.batteryCost,
                  site.currencyCode
                )})`
              : "None"}
          </strong>
        </div>
        <div style={{ fontSize: 13 }}>
          SDM:{" "}
          <strong>
            {
              site.sdmMatches.filter((match) => match.sellerId === site.id)
                .length
            }{" "}
            sell /{" "}
            {
              site.sdmMatches.filter((match) => match.buyerId === site.id)
                .length
            }{" "}
            buy
          </strong>
        </div>
      </td>
      <td style={{ fontSize: 13 }} align="left">
        {site.address}
      </td>
      <td style={{ fontSize: 13 }} align="left">
        {testMode
          ? "fixed date for test"
          : formatDateTimeFromSeconds(site.updatedAt)}
      </td>
      <td style={{ fontSize: 13, whiteSpace: "pre-wrap" }} align="left">
        {site.notes}
      </td>
    </tr>
  );
};

export default OrgSitesTable;
