import { useSite } from "@inrange/building-manager-api-client";
import { buildSitePayload } from "@inrange/shared-components";
import { currencySymbol } from "@inrange/theme-components/formatting";
import { Icons } from "@inrange/theme-components/icons";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import Loading from "../../Loading";
import { formatNumber } from "./utils";

const Battery = ({
  site,
  setSite,
  siteCalculations,
  errors,
  currencyCode,
  isOwnerOccupied,
}) => {
  const [siteInLastBatteryRequest, setSiteInLastBatteryRequest] =
    useState(undefined);
  const [results, setResults] = useState(undefined);

  const siteRef = useRef();
  siteRef.current = site;

  useEffect(() => {
    // Consume the calculated battery values and update the site state
    const update = {};
    let updateCount = 0;
    for (const field of [
      "batteryEnergyFlowPayloadHash",
      "batteryEnergyFlowFileVersionId",
    ]) {
      if (
        siteCalculations[field] &&
        siteCalculations[field] !== siteRef.current[field]
      ) {
        update[field] = siteCalculations[field];
        updateCount += 1;
      }
    }
    if (
      // Only update the battery cost from the calculations output
      // when the specs change and hence we have a new inferred price
      updateCount > 0 &&
      siteCalculations.costInputsBattery.batteryCost &&
      siteCalculations.batteryLastPriceInputs !==
        siteRef.current.batteryLastPriceInputs
    ) {
      update["costInputsBattery"] = {
        ...siteRef.current.costInputsBattery,
        batteryCost: siteCalculations.costInputsBattery.batteryCost,
      };
      update["batteryLastPriceInputs"] =
        siteCalculations.batteryLastPriceInputs;
      updateCount += 1;
    }
    if (updateCount > 0) {
      setSite(update);
    }
  }, [setSite, siteCalculations]);

  const wihoutBatteryAttributes = (site) => {
    const {
      // Model name doesn't affect table
      batteryModel: _batteryModel,
      // Rest of the battery attributes are re-calculated as part of the analysis and so are irrelevent to the comparison
      batteryPower: _batteryPower,
      batteryCapacity: _batteryCapacity,
      batteryEnergyFlowPayloadHash: _batteryEnergyFlowPayloadHash,
      batteryEnergyFlowFileVersionId: _batteryEnergyFlowFileVersionId,
      costInputsBattery: _costInputsBattery,
      ...restSite
    } = site;
    return { ...restSite };
  };

  const { createSiteBatteryAnalysis } = useSite({ app: "admin" });

  useEffect(() => {
    // If the site changes, reset the results
    if (
      siteInLastBatteryRequest &&
      !_.isEqual(
        wihoutBatteryAttributes(site),
        wihoutBatteryAttributes(siteInLastBatteryRequest)
      )
    ) {
      setSiteInLastBatteryRequest(undefined);
      setResults(undefined);
      if (createSiteBatteryAnalysis.isLoading) {
        createSiteBatteryAnalysis.reset();
      }
    }
  }, [site, siteInLastBatteryRequest, createSiteBatteryAnalysis]);

  const disableButton = Object.keys(errors).length > 0;

  const onClickAnalyze = async () => {
    setSiteInLastBatteryRequest(site);

    const sitePayload = buildSitePayload(site);

    createSiteBatteryAnalysis.mutate(
      { site: sitePayload },
      {
        onSuccess: (data) => {
          if (data) {
            setResults(data.battery_analysis);
          }
        },
        onError: (error) => {
          // We expect the battery endpoint to timeout sometimes so we treat failures as a warning rather than an error
          console.warn({ error });
          alert("Sorry - we couldn't run the battery analysis");
        },
      }
    );
  };

  return (
    <Card body className="mt-2">
      <h6
        id="battery-section"
        data-testid={"battery-section"}
        style={{ fontWeight: "bold" }}
      >
        Battery
      </h6>

      <Row>
        <Col sm={3}>
          <Form.Group className="mb-3">
            <Form.Label>Power (MW)</Form.Label>
            <Form.Control
              type="text"
              value={site.batteryPower || ""}
              onChange={(e) => {
                setSite({ batteryPower: e.target.value });
              }}
              isInvalid={errors["batteryPower"]}
              data-testid={"batteryPower"}
            />
            <Form.Control.Feedback type="invalid">
              {errors["batteryPower"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3">
            <Form.Label>Capacity (MWh)</Form.Label>
            <Form.Control
              type="text"
              value={site.batteryCapacity || ""}
              onChange={(e) => {
                setSite({ batteryCapacity: e.target.value });
              }}
              isInvalid={errors["batteryCapacity"]}
              data-testid={"batteryCapacity"}
            />
            <Form.Control.Feedback type="invalid">
              {errors["batteryCapacity"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3">
            <Form.Label>
              Battery cost ({currencySymbol(currencyCode)})
            </Form.Label>
            <Form.Control
              type="text"
              value={formatNumber(
                site.costInputsBattery.batteryCost || "",
                2,
                6,
                false
              )}
              onChange={(e) => {
                setSite({
                  costInputsBattery: {
                    ...site.costInputsBattery,
                    batteryCost: formatNumber(e.target.value, 2, 6, false),
                  },
                });
              }}
              isInvalid={errors["costInputsBattery.batteryCost"]}
              data-testid={"batteryCost"}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsBattery.batteryCost"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3">
            <Form.Label>Replacement (years)</Form.Label>
            <Form.Control
              type="text"
              value={formatNumber(
                site.costInputsBattery.batteryReplacementYear || "",
                0,
                0,
                false
              )}
              onChange={(e) =>
                setSite({
                  costInputsBattery: {
                    ...site.costInputsBattery,
                    batteryReplacementYear: formatNumber(
                      e.target.value,
                      2,
                      6,
                      false
                    ),
                  },
                })
              }
              isInvalid={errors["costInputsBattery.batteryReplacementYear"]}
              data-testid={"batteryReplacementYear"}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsBattery.batteryReplacementYear"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col sm={3}>
          <Form.Group className="mb-3" controlId="batteryInstallationCost">
            <Form.Label>
              Installation cost ({currencySymbol(currencyCode)})
            </Form.Label>
            <Form.Control
              type="text"
              value={formatNumber(
                site.costInputsBattery.installationCost || "",
                2,
                6,
                false
              )}
              onChange={(e) =>
                setSite({
                  costInputsBattery: {
                    ...site.costInputsBattery,
                    installationCost: formatNumber(e.target.value, 2, 6, false),
                  },
                })
              }
              isInvalid={errors["costInputsBattery.installationCost"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["costInputsBattery.installationCost"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group className="mb-3">
            <Form.Label>Model</Form.Label>
            <Form.Control
              type="text"
              value={site.batteryModel || ""}
              onChange={(e) => setSite({ batteryModel: e.target.value })}
              isInvalid={errors["batteryModel"]}
              data-testid={"batteryModel"}
            />
            <Form.Control.Feedback type="invalid">
              {errors["batteryModel"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}></Col>
        <Col
          sm={3}
          className="d-flex align-items-center justify-content-end pe-4"
        >
          {(!!site.batteryModel ||
            !!site.batteryPower ||
            !!site.batteryCapacity ||
            !!site.costInputsBattery.batteryCost ||
            !!site.costInputsBattery.installationCost) && (
            <a
              onClick={() =>
                setSite({
                  // We set nulls here rather than undefined so that they get sent to the backend
                  batteryModel: null,
                  batteryPower: null,
                  batteryCapacity: null,
                  batteryEnergyFlowPayloadHash: null,
                  batteryEnergyFlowFileVersionId: null,
                  costInputsBattery: {
                    ...site.costInputsBattery,
                    batteryCost: 0,
                    installationCost: 0,
                  },
                })
              }
              className="text-danger"
              style={{
                cursor: "pointer",
                textDecoration: "none",
              }}
              data-testid="remove-battery"
            >
              Remove battery
            </a>
          )}
        </Col>
      </Row>

      <h6 style={{ fontWeight: "bold" }}>Analysis</h6>

      <Row>
        <Col sm={9}>
          Analysis is constrained based on the value of “Applied grid capacity”
          in the DNO and network section.
        </Col>
        <Col sm={3} className="d-grid">
          <Button
            variant="success"
            onClick={onClickAnalyze}
            disabled={
              disableButton ||
              createSiteBatteryAnalysis.isLoading ||
              results !== undefined
            }
            data-testid={"run-battery-analysis"}
          >
            Run analysis
          </Button>
        </Col>
      </Row>

      <div style={{ marginTop: "20px" }}>
        {createSiteBatteryAnalysis.isLoading && <LoadingResults />}
        {!createSiteBatteryAnalysis.isLoading && results !== undefined && (
          <>
            {results.length > 0 && (
              <div data-testid={"battery-results-table"}>
                <ResultsView
                  site={site}
                  setSite={setSite}
                  results={results}
                  currencyCode={currencyCode}
                  isOwnerOccupied={isOwnerOccupied}
                />
              </div>
            )}
            {results.length == 0 && (
              <div>No results with positive IRR found</div>
            )}
          </>
        )}
      </div>
    </Card>
  );
};

export default Battery;

const LoadingResults = () => {
  const label = (
    <p className="text-center">
      Running analysis...
      <br />
      This can take up to 60 seconds. Don&apos;t refresh the page.
    </p>
  );
  return <Loading label={label} height="490px" />;
};

const ResultsView = ({
  site,
  setSite,
  results,
  currencyCode,
  isOwnerOccupied,
}) => {
  const columns = [
    {
      header: "Power (MW)",
      id: "power_mw",
      accessorFn: (row) => row.power_mw,
      sortingFn: "sortFnPower",
      sortDescFirst: false,
      cell: (info) => info.getValue(),
    },
    {
      header: "Capacity (MWh)",
      id: "capacity_mwh",
      accessorFn: (row) => row.capacity_mwh,
      sortingFn: "sortFnCapacity",
      sortDescFirst: false,
      cell: (info) => info.getValue(),
    },
    {
      header: `IRR (${isOwnerOccupied ? "OO" : "LL"}, %)`,
      id: "irr",
      accessorFn: (row) => row.irr,
      sortingFn: "sortIrr",
      sortDescFirst: true,
      cell: (info) => info.getValue(),
    },
    {
      header: `Revenue (Y1, ${isOwnerOccupied ? "OO" : "LL"}, License)`,
      id: "rev_year_1",
      accessorFn: (row) => formatCurrency(row.rev_year_1, currencyCode),
      sortingFn: "sortFnRev",
      sortDescFirst: true,
      cell: (info) => info.getValue(),
    },
    {
      header: "Battery cost",
      id: "battery_cost",
      accessorFn: (row) => formatCurrency(row.battery_cost, currencyCode),
      sortingFn: "sortFnBattery",
      sortDescFirst: false,
      cell: (info) => info.getValue(),
    },
    {
      header: "Total fulfilled by on-site (%)",
      id: "tenant_demand_share",
      accessorFn: (row) =>
        (
          row.energyFlowAnnual.behindMeter / row.energyFlowAnnual.demand
        ).toFixed(4),
      sortingFn: "sortFnTenantDemand",
      sortDescFirst: true,
      cell: (info) => info.getValue(),
    },
    {
      header: "Tenant energy share (%)",
      id: "tenant_energy_share",
      accessorFn: (row) =>
        (
          row.energyFlowAnnual.behindMeter / row.energyFlowAnnual.generation
        ).toFixed(4),
      sortingFn: "sortFnTenantGeneration",
      sortDescFirst: true,
      cell: (info) => info.getValue(),
    },
    {
      header: "Export (kWh/yr)",
      id: "export_kwh",
      accessorFn: (row) =>
        new Intl.NumberFormat("en-GB", {
          style: "decimal",
          maximumFractionDigits: 0,
        }).format(row.energyFlowAnnual.exported),
      sortingFn: "sortFnExport",
      sortDescFirst: true,
      cell: (info) => info.getValue(),
    },
    {
      header: "Curtailed (kWh/yr)",
      id: "curtailment_kwh",
      accessorFn: (row) =>
        new Intl.NumberFormat("en-GB", {
          style: "decimal",
          maximumFractionDigits: 0,
        }).format(row.energyFlowAnnual.curtailed),
      sortingFn: "sortFnExport",
      sortDescFirst: true,
      cell: (info) => info.getValue(),
    },
    {
      header: "",
      id: "actions",
      accessorFn: (row) => row,
      cell: ({ row }) => {
        const disabled =
          Number(site.batteryPower) === row.original.power_mw &&
          Number(site.batteryCapacity) === row.original.capacity_mwh &&
          Number(site.costInputsBattery.batteryCost) ===
            row.original.battery_cost;
        return (
          <Button
            variant="success"
            onClick={() => {
              setSite({
                batteryPower: row.original.power_mw,
                batteryCapacity: row.original.capacity_mwh,
                batteryEnergyFlowPayloadHash:
                  row.original.battery_energy_flow_payload_hash,
                batteryEnergyFlowFileVersionId:
                  row.original.battery_energy_flow_file_version_id,
                costInputsBattery: {
                  ...site.costInputsBattery,
                  batteryCost: row.original.battery_cost,
                },
              });
            }}
            disabled={disabled}
            style={{ padding: "0.375rem", width: "100%" }}
            size="sm"
          >
            {disabled ? "Selected" : "Select"}
          </Button>
        );
      },
      disableSortBy: true,
    },
  ];

  return <ResultsTable columns={columns} data={results} />;
};

const sortByCellValue = (rowA, rowB, columnId) => {
  const valueA = rowA.original[columnId];
  const valueB = rowB.original[columnId];
  return valueA < valueB ? -1 : 1;
};

const ResultsTable = ({ columns, data }) => {
  const [sorting, setSorting] = useState([
    { id: columns[2].id ?? "", desc: true },
  ]);
  const table = useReactTable({
    data,
    columns,
    sortingFns: {
      sortFnPower: (rowA, rowB) => sortByCellValue(rowA, rowB, "power_mw"),
      sortFnCapacity: (rowA, rowB) =>
        sortByCellValue(rowA, rowB, "capacity_mw"),
      sortIrr: (rowA, rowB) => sortByCellValue(rowA, rowB, "irr"),
      sortFnRev: (rowA, rowB) => sortByCellValue(rowA, rowB, "rev_year_1"),
      sortFnBattery: (rowA, rowB) =>
        sortByCellValue(rowA, rowB, "battery_cost"),
      sortFnTenantGeneration: (rowA, rowB) =>
        sortByCellValue(rowA, rowB, "tenant_energy_share"),
      sortFnTenantDemand: (rowA, rowB) =>
        sortByCellValue(rowA, rowB, "tenant_demand_share"),
      sortFnExport: (rowA, rowB) => sortByCellValue(rowA, rowB, "export_kwh"),
      sortFnCurtailedExport: (rowA, rowB) =>
        sortByCellValue(rowA, rowB, "curtailment_kwh"),
    },
    state: {
      sorting,
    },
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Table
      striped
      bordered
      hover
      responsive
      style={{
        fontSize: "90%",
        height: "400px",
        overflow: "scroll",
        display: "block",
        border: "1px solid #dee2e6",
      }}
      id="battery-results-table"
    >
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                style={{ cursor: "pointer", padding: "0.3rem" }}
                onClick={header.column.getToggleSortingHandler()}
              >
                <div style={{ display: "flex" }}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                  <SortArrow isSorted={header.column.getIsSorted()} />
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} style={{ padding: "0.3rem" }}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const SortArrow = ({ isSorted }) => {
  if (!isSorted) {
    return (
      <span
        style={{
          width: 8,
          display: "inline-block",
        }}
      >
        &nbsp;
      </span>
    );
  }
  const arrow = isSorted === "desc" ? Icons.sortDesc : Icons.sortAsc;

  return <img alt="view" src={arrow} />;
};

const formatCurrency = (value, currencyCode) => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: currencyCode,
    maximumFractionDigits: 0,
  }).format(value);
};
